<template>
  <div class="">
		<div class="m-3 d-flex justify-content-between">
			<a href="#" @click="onBackClicked">Back</a>
		</div>

    <div class="my-5 pb-5 container">

      <div class="py-3">
        <p class="m-0 competition-detail-title">{{ $t('team_name') + ' ' + (event_category_sport_category.id != null ? event_category_sport_category.name : '') }}</p>
        <table class="table table-member table-striped d-block d-md-table">
          <thead>
            <tr>
              <th v-for="(tab, index) in arr_tab" :key="index" class="competition-detail-category-th">{{ tab.name }}</th>
            </tr>
          </thead>

          <tbody v-if="arr.length > 0">
            <tr v-for="(category, index) in arr" :key="index">
              <td v-for="(tab, index1) in arr_tab" :key="index1" class="competition-detail-category-td" style="vertical-align: middle;">
								<div :class="{'competition-detail-category-td-wrap': true}">
									{{ category[tab.id] }}
								</div>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td class="text-center competition-detail-category-td" :colspan="arr_tab.length">{{ $t('no_data_found') }}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';


import Icon_Download from '@/assets/download.png'

export default {
  components: {
  },
	props: ['event_category_sport_category', 'competition_data', ],
  data() {
    return {
      base: null,
      scrollY: 0,
      icon_download: Icon_Download,
      arr_factor: [false, false, false,],
      flag: {
        aboutUsTitle1Flag: false,
        aboutUsImage1Flag: false,
        aboutUsContent1Flag: false,
        aboutUsTitle2Flag: false,
        aboutUsImage2Flag: false,
        aboutUsContent2Flag: false,
        aboutUsTitle3Flag: false,
        aboutUsImage3Flag: false,
        aboutUsContent3Flag: false,
      },
      arr_tab: [
        {
          id: 'user_name',
          name_id: 'user_name',
        },
        {
          id: 'team_name',
          name_id: 'team_name',
        },
        {
          id: 'event_category_sport_category_name',
          name_id: 'category',
        },
        {
          id: 'status',
          name_id: 'status',
        },
      ],
      arr: [],
    }
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      this.manage_start_animation()
    },
    scrollY() {
      this.manage_start_animation()
    },
		event_category_sport_category(val){
			// console.log(val)
			this.get_registration_data()
		}
  },
  created() {
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1
    // this.arr_factor = [true, true, true,]

    for(let tab of this.arr_tab)
      tab.name = this.$t(tab.name_id)

		// this.get_competition_data()
		// this.get_event_category_sport_category()
  },
  methods: {
		onBackClicked() {
			// if(this.type == 'player_attendance')
			//   this.type = 'match_event'
			// else
			this.$emit('onBackClicked')
		},
    manage_start_animation() {
      var context = this
      this.flag.aboutUsTitle1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsTitle1Flag, this.arr_factor, 0)
      this.flag.aboutUsImage1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsImage1Flag, this.arr_factor, 0)
      this.flag.aboutUsContent1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsContent1Flag, this.arr_factor, 0)

      setTimeout(() => {
        if (context.flag.aboutUsContent1Flag) {
          var margin = window.$('#about-us-content').height()
          context.flag.aboutUsTitle2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle2Flag, context.arr_factor, 0, margin - margin)
          context.flag.aboutUsContent2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent2Flag, context.arr_factor, 0, margin - 300)

          if (context.flag.aboutUsContent2Flag) {
            context.flag.aboutUsImage2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage2Flag, context.arr_factor, 0, margin + window.$('#trust-content').innerHeight() - 300)

            if (context.flag.aboutUsImage2Flag) {
              margin += window.$('#trust-container').innerHeight() - 950
              context.flag.aboutUsTitle3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsImage3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsContent3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent3Flag, context.arr_factor, 800, margin)
            }
          }
        }
      }, 200)

    },
    handleScroll() {
      this.scrollY = window.scrollY
    },
    async get_registration_data(){
      var response = await this.base.request(this.base.url_api + "/event/registration/all?status=success&event_category_sport_category_id=" + this.event_category_sport_category.id)
      this.$set(this.arr_factor, 0, true)

      if(response != null){
        if(response.status === "success"){
          this.arr = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
.competition-detail-event{
  border-bottom: 1px solid black;
}
.competition-detail-event p{
  font-family: 'montserrat-regular';
  font-size: 1rem;
  color: $gray20;
}
.competition-detail-category-th{
  font-family: 'montserrat-medium';
  color: $primary;
}
.competition-detail-category-th.custom{
  font-size: 1.2rem;
  font-weight: 600;
}
.competition-detail-category-td{
  font-family: 'montserrat-regular';
  color: $gray20;
}
.competition-detail-category-td-wrap{
  min-width: 250px;
  text-wrap: balance;
}

.competition-detail-category-td.custom{
  font-weight: 600;
}

.competition-detail-title{
  font-family: 'montserrat-bold';
  font-size: 2rem;
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

.competition-guide-item-grid{
  display: grid;
  grid-template-columns: 5rem 1fr auto;
  gap: 1rem;
  margin-bottom: 1rem;
}

// .form-control{
//   background-position: right .75rem center;
// }
</style>

<template>
  <!-- <div class="row bg-login custom-navbar-padding-left custom-navbar-padding-right min-vh-100 pb-5 pb-md-0"> -->
  <div class="row bg-login p-5 justify-content-center position-relative">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style=" position: absolute; right: 0; top: 0;">
      <img src="@/assets/login_close.png">
    </button>
    <!-- <div class="col-12 col-md-6 d-flex align-items-center justify-content-center"> -->
    <div class="col-auto d-flex align-items-center justify-content-center">
      <img src="@/assets/logo_new.png" style="max-width: 240px;">
    </div>

    <div class="col-auto col-lg d-flex align-items-center justify-content-center position-relative">
      <!-- <a href="/" class="position-absolute" style="top: 1rem; right: 0rem;">
      </a> -->
      <!-- <div style="max-width: 25.6rem;"> -->
      <div style="max-width: 25.6rem;">
        <p class="m-0 text-primary login-title">Sport Club Category</p>
        <p class="m-0 text-primary login-detail">Before continue, please insert your sport club category</p>

        <div class="form-group mt-3">
					<p class="m-0 text-white login-detail">Sport Club Category</p>
					<!-- <input type="text" v-model="school_name" class="form-control" style="height: 2.375rem"> -->
					<select v-model="category_id" class="form-control" style="height: 2.375rem">
						<option selected value="">Choose Category</option>
						<option v-for="(category, index) in arr_category" :key="index" :value="category.id">{{ category.name }}</option>
					</select>
				</div>

        <div class="mt-3">
          <button class="btn btn-primary text-warning w-100" @click="onSubmit">{{ $t('submit') }}</button>
          <!-- <p class="m-0 text-primary login-forget mt-3">By clicking on Sign up, you agree to our Terms of service and Privacy policy.</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import Base from '@/utils/base';

export default {
  components: {
  },
  data() {
    return {
      base: null,
      arr_category: [],
			category_id: '',
    }
  },
  created() {
    this.base = new Base()
		this.get_category()
  },
  methods: {
    onKeyup(e){
      if(e.keyCode == 13)
        this.onLogin()
    },
		async get_category(){
			var response = await this.base.request(this.base.url_api + "/category-sport/all")

			if(response != null){
				if(response.status === "success"){
					this.arr_category = response.data
				}
				else
					this.base.show_error(response.message)
			}
			else
				console.log(this.$t('server_error'))
		},
    async onSubmit() {
      if(this.category_id == '')
        this.base.show_error('Sport Club Category is Empty')
      else{
        window.$('#please_wait_modal').modal('show')

				var user_data = await window.localStorage.getItem('user')
				if(user_data != null){
					user_data = JSON.parse(user_data)
				}

        var response = await this.base.request(this.base.url_api + "/auth/change-category-sport", 'post', {
          category_id: this.category_id,
					id: user_data.id,
        })

        setTimeout(() => {
          window.$('#please_wait_modal').modal('hide')
        }, 500)
        if (response != null) {
          if (response.status === "success") {
						var token_temp = await window.localStorage.getItem('token_temp')
            window.localStorage.setItem('token', await window.localStorage.getItem('token_temp'))
            window.localStorage.setItem('token_expired', moment().add(6, 'hours').format('YYYY-MM-DD HH:mm:ss'))

            var competition_data = await window.localStorage.getItem('competition_data')
            if(competition_data != null){
              competition_data = JSON.parse(competition_data)
              window.localStorage.removeItem('competition_data')
            }
						window.localStorage.removeItem('token_temp')

            if(user_data.type.name == 'coordinator')
              window.location.reload()
            else
              window.location.href = this.base.admin_host + "?token=" + token_temp + (competition_data != null ? '&competition_id=' + competition_data.id : '')
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    },
  }
}
</script>

<style lang="scss">
.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

html,
body,
body .fullscreen {
  height: 100%;
}

.bg-login {
  background-image: linear-gradient(to right, $primary , $yellow3);
}

.login-hr {
  width: 100%;
  height: 1px;
  border: 1px solid $primary;
}

.login-title {
  font-family: 'inter-medium';
  font-size: 2rem;
}

.login-detail {
  font-family: 'inter-regular';
}

.login-forget {
  font-family: 'inter-light';
}

.login-reset-password {
  font-family: 'inter-medium';
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}</style>

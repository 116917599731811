<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail
        :title="competition_data.category_sport != null && competition_data.category_sport.name != null ? competition_data.category_sport.name : ''"
      />
    </div>
    <CompetitionTab :competition_data="competition_data" />

    <div class="my-5 pb-5 container" v-show="!isDetailFlag">
      <div class="py-3">
        <p class="m-0 competition-detail-title">{{ $t("tournament") }}</p>

        <div class="py-3">
					<div class="d-flex">

						<div class="form-group">
							<select
								class="form-control"
								style="
									font-size: 0.75rem;
									height: 2.5rem;
									width: auto;
									background-position: right 0.75rem center;
								"
								v-model="type">
								<option value="">All</option>
								<option value="club">Sport Club</option>
								<option value="school">Academy</option>
							</select>
						</div>

						<div class="form-group ml-3">
							<select
								class="form-control"
								style="font-size: 0.75rem; height: 2.5rem; width: auto; background-position: right 0.75rem center"
								v-model="category">
								<option value="">All</option>
								<option
									:value="event_category_sport_category.id"
									v-for="(event_category_sport_category, index) in arr_event_category_sport_category"
									:key="index">
									{{ event_category_sport_category.name }}
								</option>
							</select>
						</div>

					</div>

          <!-- template tournament tree -->
          <div v-if="arr_group.length > 0">
            <div v-for="(group, index) in arr_group" :key="index" class="mt-5">
							<div class="d-flex align-items-center mb-3">
								<p class="m-0">{{ group.type != null ? group.type.name + ' / ' : '' }}{{ group.event_category_sport_category.name }}</p>
								<button class="btn btn-primary ml-3" v-show="user != null && user.is_coordinator && group.allow_edit" @click="onEditClicked(index)">Edit</button>
							</div>

              <div class="wrapper">
                <TournamentBracket :group="group" class="wrapper-inside" @onMatchClicked="onMatchClicked" @onMatchEditClicked="onMatchEditClicked" :user="user"/>
              </div>
            </div>
          </div>
          <div v-else>
            <h4 class="text-center">No Tournament Found</h4>
          </div>
          <!-- end of template tournament tree -->

          <!-- <div>
            <div
              v-for="(group, index) in arr_group"
              :key="index"
              class="mb-5 overflow-auto"
            >
              <h4 class="position-sticky mb-4" style="left: 0">
                {{ group.type.name }} /
                {{ group.event_category_sport_category.name }}
              </h4>

              <div class="d-flex h-100" style="overflow-x: scroll">
                <div
                  v-for="(level, index1) in group.arr"
                  :key="index1"
                  class="mr-5 d-flex flex-column justify-content-center"
                >
                  <div v-for="(match, index2) in level.arr" :key="index2" >
                    <div
                      :style="{
                        marginTop:
                          (index2 > 0 && index2 % 2 == 1 && index2 > 0
                            ? (index1 + 1) * 9
                            : index2 > 0 && index2 % 2 == 0
                            ? 1
                            : 0) + 'rem',
                        marginBottom:
                          (index2 > 0 && index2 % 2 == 1 && index2 > 0
                            ? (index1 + 1) * 9
                            : index2 > 0 && index2 % 2 == 0
                            ? 1
                            : 0) + 'rem',
                        height: index1 > 0 && index2 > 0 ? '2rem' : '1px',
                      }"
                    ></div>

                    <div class="position-relative">
                      <img
                        src="@/assets/arrow_down.png"
                        class="position-absolute"
                        v-if="index1 > 0"
                        :style="{
                          top: index1 * -9.5 + 'rem',
                          left: '-3rem',
                          width: '15rem',
                          height: index1 * 9.5 + 'rem',
                        }"
                      />

                      <div @click="onSelectedMatch(index, index1, index2)">
                        <div class="card">
                          <div class="card-body p-3">
                            <div
                              class="d-flex justify-content-between align-items-center"
                              v-if="match.registration_event1 != null"
                            >
                              <div class="mr-5">
                                <h4 class="m-0">
                                  {{ match.registration_event1.team_name }}
                                </h4>
                                <p class="m-0">
                                  {{ match.registration_event1.user.name }}
                                </p>
                              </div>
                              <div class="d-flex align-items-center">
                                <img
                                  :src="match.registration_event1.url_image"
                                  style="width: 5rem"
                                />
                                <h4 class="m-0 ml-3">
                                  {{ match.match[0].group_member1_score }}
                                </h4>
                              </div>
                            </div>
                            <div v-else>
                              <h4 class="m-0">BYE</h4>
                            </div>
                          </div>
                        </div>

                        <div class="text-center my-3">
                          {{ match.date_moment.format("DD MMMM YYYY HH:mm") }}
                        </div>

                        <div class="card">
                          <div class="card-body p-3">
                            <div
                              class="d-flex justify-content-between align-items-center"
                              v-if="match.registration_event2 != null"
                            >
                              <div>
                                <h4 class="m-0">
                                  {{ match.registration_event2.team_name }}
                                </h4>
                                <p class="m-0">
                                  {{ match.registration_event2.user.name }}
                                </p>
                              </div>
                              <div class="d-flex align-items-center">
                                <img
                                  :src="match.registration_event2.url_image"
                                  style="width: 5rem"
                                />
                                <h4 class="m-0 ml-3">
                                  {{ match.match[0].group_member2_score }}
                                </h4>
                              </div>
                            </div>
                            <div v-else>
                              <h4 class="m-0">BYE</h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <img
                        src="@/assets/arrow_up.png"
                        class="position-absolute"
                        v-if="index1 > 0"
                        :style="{
                          bottom: index1 * -9.5 + 'rem',
                          left: '-3rem',
                          width: '15rem',
                          height: index1 * 9.5 + 'rem',
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <MatchDetail
      :match_data="selected_match_detail"
      v-show="isDetailFlag"
      @onBackClicked="onBackDetailMatch"
      @onForceReload="onForceReload"
    />
    <ModalMatch v-if="user != null && user.type != null && user.type.name == 'coordinator'" :match="selected_match" :data="competition_data" :onShow="is_show_modal" @onForceReload="onForceReload"/>
		<ModalEditTournament :group_data="group_data" @onSaveClicked="onEditTournamentSuccess"/>
  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";

import NavbarDetail from "@/layout/navbar_detail.vue";
import CompetitionTab from "@/pages/competition/tab.vue";
import MatchDetail from "@/pages/competition/match_detail.vue";
import ModalMatch from "@/pages/competition/modal_match";
import TournamentBracket from "@/pages/competition/component/tournament_bracket.vue";
import ModalEditTournament from "@/pages/competition/modal_edit_tournament.vue";

import NoImage from "@/assets/no_image.png";

export default {
  components: {
    NavbarDetail: NavbarDetail,
    CompetitionTab: CompetitionTab,
    MatchDetail: MatchDetail,
    'ModalMatch': ModalMatch,
    TournamentBracket: TournamentBracket,
		ModalEditTournament: ModalEditTournament,
  },
  data() {
    return {
      base: null,
      arr_factor: [false, false],
      type: "",
			category: "",
      arr_group: [],
      arr_group_html: [],
      competition_data: {},
      selected_match: {},
      selected_match_detail: {},
			arr_event_category_sport_category: [],
      isDetailFlag: false,
      is_show_modal: false,
			group_data: {},
      user: {},
    };
  },
  watch: {
    // user(val){
    //   console.log(val != null && val.type != null && val.type.name == 'coordinator')
    // },
    arr_factor(val) {
      this.$emit("onChangeArrFactor", val);
      // this.manage_start_animation()
    },
    type() {
			window.$('#please_wait_modal').modal('show')
      this.get_data();
    },
		category(){
			window.$('#please_wait_modal').modal('show')
			this.get_data();
		},
		group_data(val){
			window.$('#editTournament').modal('show')
		},
    arr_group(val){
      // var arr_group_html = []
      // var str = ''
      // for(let x in val){
      //   str += '<div class="item">'
      //     str += '<div class="item-parent">'
      //       str += `<p>${val[x].arr[val[x].arr.length - 1].arr[0].registration_event1.team_name}</p>`
      //     str += '</div>'
      //     if(val[x].arr[val[x].arr.length - 2] != null)
      //         str += this.manage_arr_group(val[x].arr, val[x].arr.length - 2)
      //   str += '</div>'
      //   arr_group_html[x] = str
      // }
      // this.arr_group_html = arr_group_html
    },
  },
  async created() {
    this.base = new Base();
    // window.addEventListener('scroll', this.handleScroll)
    this.arr_factor = [true];

    var user = await window.localStorage.getItem('user')
    this.user = JSON.parse(user)

		this.get_user_data()
		this.get_event_category_sport_category_data()
    this.get_competition_data();
    this.get_data();
  },
  methods: {
    // manage_arr_group(arr, index){
    //   var str = '<div class="item-childrens">'
    //   for(let match of arr[index].arr){
    //     str += '<div class="item-child">'
    //     if(arr[index - 1] != null){
    //       str += '<div class="item">'
    //         str += '<div class="item-parent">'
    //     }
    //           str += `<p>${match.registration_event1.team_name}</p>`
    //           str += `<p>${match.registration_event2.team_name}</p>`
    //     if(arr[index - 1] != null){
    //         str += '</div>'
    //         str += this.manage_arr_group(arr, index - 1)
    //       str += '</div>'
    //     }
    //     str += '</div>'
    //   }
    //   str += '</div>'
    //   return str
    // },
		async get_user_data() {
			var response = await this.base.request(this.base.url_api + "/auth/profile?event_category_sport_id=" + this.$route.query.id);

			if (response != null) {
				if (response.status === "success") {
					this.user = response.data;
				} else this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
		async get_event_category_sport_category_data() {
			var response = await this.base.request(
				this.base.url_api + "/event/category-sport/category/all?event_category_sport_id=" + this.$route.query.id
			);
			this.$set(this.arr_factor, 1, true);

			if (response != null) {
				if (response.status === "success") {
					this.arr_event_category_sport_category = response.data;
				} else this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
    onMatchClicked(match){
      this.selected_match_detail = match
      this.isDetailFlag = true
    },
    onMatchEditClicked(match){
      this.selected_match = match
      this.is_show_modal = true
      window.$('#editMatch').modal('show')
    },
    onSelectedMatch(index, index1, index2) {
      this.selected_match =
        this.arr_group[index].arr[index1].arr[index2].match[0];
      this.isDetailFlag = true;
    },
    onBackDetailMatch() {
      this.isDetailFlag = false;
      this.arr_group = []
      this.get_data();
    },
		onEditClicked(index){
			var group = this.arr_group[index]
			this.group_data = group


			// window.location.href = '/tournament/edit?type_id=' + (group.type != null ? group.type.id : '') + '&event_category_sport_category_id=' + group.event_category_sport_category.id
		},
		onEditTournamentSuccess(){
			this.group_data = {}
			window.$('#editTournament').modal('hide')

			this.get_data()
		},
    onForceReload() {
      this.get_data();
    },
    async get_competition_data() {
      var response = await this.base.request(
        this.base.url_api + "/event/category-sport?id=" + this.$route.query.id
      );
      this.$set(this.arr_factor, 0, true);

      if (response != null) {
        if (response.status === "success") {
          response.data.start_date_moment = momentTZ.tz(
            response.data.start_date,
            this.base.locale_timezone
          );
          response.data.end_date_moment = momentTZ.tz(
            response.data.end_date,
            this.base.locale_timezone
          );
          response.data.event.start_school_registration_moment = momentTZ.tz(
            response.data.event.start_school_registration,
            this.base.locale_timezone
          );
          response.data.event.end_school_registration_moment = momentTZ.tz(
            response.data.event.end_school_registration,
            this.base.locale_timezone
          );
          response.data.event.start_registration_team_moment = momentTZ.tz(
            response.data.event.start_registration_team,
            this.base.locale_timezone
          );
          response.data.event.end_registration_team_moment = momentTZ.tz(
            response.data.event.end_registration_team,
            this.base.locale_timezone
          );
          response.data.event.start_payment_registration_moment = momentTZ.tz(
            response.data.event.start_payment_registration,
            this.base.locale_timezone
          );
          response.data.event.end_payment_registration_moment = momentTZ.tz(
            response.data.event.end_payment_registration,
            this.base.locale_timezone
          );
          response.data.event.coach_meeting_moment = momentTZ.tz(
            response.data.event.coach_meeting,
            this.base.locale_timezone
          );
          response.data.event.medal_ceremony_moment = momentTZ.tz(
            response.data.event.medal_ceremony,
            this.base.locale_timezone
          );

          this.competition_data = response.data;
        } else this.base.show_error(response.message);
      } else console.log(this.$t("server_error"));
    },
    async get_data() {
			var counter = 0
			for(let factor of this.arr_factor){
				if(!factor)
					break
				counter++
			}

			if(counter == this.arr_factor.length)
				window.$('#please_wait_modal').modal('show')

      this.arr_group = []
      var response = await this.base.request(
        this.base.url_api +
          "/tournament/group?type=" + this.type +
					"&event_category_sport_category_id=" + this.category +
          "&event_category_sport_id=" + this.$route.query.id
      );
      this.$set(this.arr_factor, 1, true);
			window.$('#please_wait_modal').modal('hide')

      if (response != null) {
        if (response.status == "success") {
          for (let group of response.data) {
            for (let level of group.arr) {
              for (let tournament of level.arr) {
                var match = tournament.match[0]

								var event_category_sport_category = match.tournament.event_category_sport_category
								match.event_category_sport_category = match.tournament.event_category_sport_category
								if(event_category_sport_category.event_category_sport.cutoff_scoring_type == 'timer'){
									match.group_member1_score_timer = momentTZ.duration(match.group_member1_score, 's')
									match.group_member2_score_timer = momentTZ.duration(match.group_member2_score, 's')
								}

                match.date = momentTZ.tz(match.date, this.base.locale_timezone);
                tournament.date_moment = momentTZ.tz(tournament.date, this.base.locale_timezone);
                if(tournament.registration_event1 != null)
                  tournament.registration_event1.url_image = tournament.registration_event1.url_image != null ? tournament.registration_event1.url_image : NoImage
                else{
                  tournament.registration_event1 = {}
                  tournament.registration_event1.url_image = NoImage
                  tournament.registration_event1.team_name = tournament.registration_event2 != null && tournament.status == 'finished' ? 'BYE' : 'TBA'
                  tournament.registration_event1.user = {}
                  tournament.registration_event1.user.name = tournament.registration_event2 != null && tournament.status == 'finished' ? 'BYE' : 'TBA'
                }
                if(tournament.registration_event2 != null)
                  tournament.registration_event2.url_image = tournament.registration_event2.url_image != null ? tournament.registration_event2.url_image : NoImage
                else{
                  tournament.registration_event2 = {}
                  tournament.registration_event2.url_image = NoImage
                  tournament.registration_event2.team_name = tournament.registration_event1 != null && tournament.status == 'finished' ? 'BYE' : 'TBA'
                  tournament.registration_event2.user = {}
                  tournament.registration_event2.user.name = tournament.registration_event1 != null && tournament.status == 'finished' ? 'BYE' : 'TBA'
                }

                match.group = null
                match.member1 = {}
                match.member2 = {}
                match.tournament = tournament
                if(tournament.registration_event1 != null)
                  match.member1.registration_event = tournament.registration_event1
                if(tournament.registration_event2 != null)
                  match.member2.registration_event = tournament.registration_event2
              }
            }
          }
          this.arr_group = response.data;
        } else this.base.show_error(response.message);
      } else console.log(this.$t("server_error"));
    },
  },
};
</script>

<style lang="scss">
.ladder-table td {
  vertical-align: middle;
}

.competition-detail-venue-court {
  font-family: "montserrat-regular";
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list > p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list > p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member > tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

// .form-control{
//   background-position: right .75rem center;
// }

$side-margin: 50px;
$vertical-margin: 10px;

.wrapper {
  // display: flex;
  // max-height: 600px;
  // width: max-content;
  // justify-content: center;
  // overflow: scroll;
  height: 500px;
  overflow:auto;
  background-color: #fafafa;
}

.wrapper-inside{
  width: fit-content;
  padding: 1rem 2rem;

}

.item {
  display: flex;
  flex-direction: row-reverse;
  // p {
  //   padding: 20px;
  //   margin: 0;
  //   background-color: Beige;
  // }

  &-parent {
    position: relative;
    margin-left: $side-margin;
    display: flex;
    align-items: center;
    &:after {
      position: absolute;
      content: "";
      width: $side-margin/2;
      height: 2px;
      left: 0;
      top: 50%;
      background-color: var(--primary);
      transform: translateX(-100%);
    }
  }
  &-childrens {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  &-child {
    // display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: $vertical-margin;
    margin-bottom: $vertical-margin;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background-color: var(--primary);
      right: 0;
      top: 50%;
      transform: translateX(100%);
      width: 25px;
      height: 2px;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: var(--primary);
      right: -$side-margin / 2;
      height: calc(50% + 22px);
      width: 2px;
      top: 50%;
    }
    &:last-child {
      &:after {
        transform: translateY(-100%);
      }
    }
    &:only-child:after {
      display: none;
    }
  }
}
</style>

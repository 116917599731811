<template>
  <div>
    <div class="row my-4">
      <div class="col-6 mb-3" v-for="(registration, index) in arr_registration" :key="index" >

        <div class="">
          <div
            class="d-flex flex-row-reverse"
            style="gap: 0.5rem">

            <div
              class="d-flex flex-column flex-md-row flex-fill align-items-start align-items-md-center cursor-pointer"
              style="gap: 0.5rem"
              @click="$emit('onEventClicked', registration)">

              <div
                class="d-flex align-items-center justify-content-center"
                style="width: 4rem; height: 4rem">
                <img
                  :src="registration.url_image"
                  class="mw-100 mh-100"/>
              </div>

              <div
                class="flex-fill"
                style="gap: 0.5rem">
                <p class="m-0" style="min-width: 2rem">
                  {{ registration.user.name }}
                </p>
								<p class="m-0 font-weight-bold text-primary">
									{{ registration.team_name }}
								</p>
								<small class="m-0">
									{{ registration.jersey_team }}
								</small>
              </div>

              <div class="d-flex align-items-baseline flex-row flex-md-row-reverse" style="gap: 0.5rem">
								<div v-if="registration.match_event != null">
									<p class="m-0 text-center" style="min-width: 2rem" v-if="registration.match_event.timer_moment != null">
										{{
											(registration.match_event.timer_moment.hours() < 10 ? '0' + registration.match_event.timer_moment.hours() : registration.match_event.timer_moment.hours()) + ':' +
											(registration.match_event.timer_moment.minutes() < 10 ? '0' + registration.match_event.timer_moment.minutes() : registration.match_event.timer_moment.minutes()) + ':' +
											(registration.match_event.timer_moment.seconds() < 10 ? '0' + (registration.match_event.timer_moment.seconds()) : registration.match_event.timer_moment.seconds()) + '.' +
											(registration.match_event.timer_moment.milliseconds() < 10 ?  '00' + (registration.match_event.timer_moment.milliseconds())  : registration.match_event.timer_moment.milliseconds() < 100 ? '0' + registration.match_event.timer_moment.milliseconds() : registration.match_event.timer_moment.milliseconds())
										}}
									</p>
									<p class="m-0 text-center" style="min-width: 2rem" v-else-if="registration.match_event.minute_time != 0">
										'{{ registration.match_event.minute_time }}
									</p>
									<p class="m-0 text-center" style="min-width: 2rem" v-else>
										No {{ match.cutoff_group.event_category_sport.cutoff_scoring_type == 'score' ? 'Score' : 'Time' }}
									</p>
								</div>

								<div v-else>
									<p class="m-0 text-center" style="min-width: 2rem">
										No {{ match.cutoff_group.event_category_sport.cutoff_scoring_type == 'score' ? 'Score' : 'Time' }}
									</p>
								</div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";

export default {
  props: ["arr_match_event", "match", "user"],
  components: {},
  data() {
    return {
      base: null,
			arr_registration: [],
    };
  },
	watch: {
		arr_match_event(val){
			this.manage_arr_registration()
		},
	},
  async mounted() {
    this.base = new Base();

		if(this.match.cutoff_group != null){
			this.manage_arr_registration()
		}
  },
  methods: {
		manage_arr_registration(){
			var arr_registration = []

			for(let member of this.match.cutoff_group.member){

				for (let event of this.arr_match_event) {
					if(event.registration_event_id == member.registration_event.id){
						member.registration_event.match_event = event
						break
					}
				}
				arr_registration.push(member.registration_event)

			}
			this.arr_registration = arr_registration
		},
	},
};
</script>

<style lang="scss">
.matchEventGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
</style>

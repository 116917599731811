<template>
	<div>
		<div class="mb-5">
			<h4>
				{{ type != null ? type.name + ' / ' : '' }}
				{{ event_category_sport_category.name }}
			</h4>
			<div v-for="(tournament, index1) in group_data.arr" :key="index1" class="row">
				<div class="col-12 d-flex" :class="{'mb-3': index1 < group_data.arr.length - 1,}">
					<select class="form-control mr-1" :value="tournament.registration_event1.id" @change="onChange(index1, 'registration_event1', $event)">
						<option v-for="(member1, index2) in arr_member" :key="index2" :value="member1.id">{{ member1.team_name }} ({{ member1.user.name }})</option>
					</select>
					<select class="form-control ml-1" :value="tournament.registration_event2.id" @change="onChange(index1, 'registration_event2', $event)">
						<option v-for="(member1, index2) in arr_member" :key="index2" :value="member1.id">{{ member1.team_name }} ({{ member1.user.name }})</option>
					</select>
				</div>
			</div>
		</div>

		<div class="d-flex">
			<button class="btn btn-outline-primary" @click="onSaveClicked">Save</button>
		</div>
	</div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from 'moment-timezone'

export default {
	props: ["group", "event_category_sport_category", 'type',],
	data() {
		return {
			base: new Base(),
			group_data: {},
			arr_member: [],
		};
	},
	mounted() {
		this.group_data = this.group

		var arr_member = []
		for(let tournament of this.group.arr){
			arr_member.push(tournament.registration_event1)
			arr_member.push(tournament.registration_event2)
		}
		this.arr_member = arr_member
	},
	methods: {
		onChange(index, type, event){
			var group_data = JSON.parse(JSON.stringify(this.group_data))
			var oldValue = group_data.arr[index][type].id
			var newValue = event.target.value
			var oldIndex = -1
			var oldType = ''
			var newIndex = index
			var newType = ''


			for(let x in group_data.arr){

				if(group_data.arr[x].registration_event1.id == newValue){
					newValue = JSON.parse(JSON.stringify(group_data.arr[x].registration_event1))
					newIndex = x
					newType = 'registration_event1'
				}
				if(group_data.arr[x].registration_event2.id == newValue){
					newValue = JSON.parse(JSON.stringify(group_data.arr[x].registration_event2))
					newIndex = x
					newType = 'registration_event2'
				}
				if(group_data.arr[x].registration_event1.id == oldValue)
					oldValue = JSON.parse(JSON.stringify(group_data.arr[x].registration_event1))
				if(group_data.arr[x].registration_event2.id == oldValue)
					oldValue = JSON.parse(JSON.stringify(group_data.arr[x].registration_event2))
			}

			if(newValue.id == null){
				var arr_member = this.arr_member
				for(let member of arr_member){
					if(newValue == member.id){
						newValue = member
						break
					}
				}
			}

			group_data.arr[newIndex][newType] = oldValue
			group_data.arr[index][type] = newValue

			this.group_data = group_data
		},
		onSaveClicked(){
			var message = ''
			for(let x in this.group_data.arr){
				if(this.group_data.arr[x].registration_event1.id == null && this.group_data.arr[x].registration_event2.id == null){
					message = "Match #" + (parseFloat(x) + 1) + ' does not have any team'
					break
				}
			}

			for(let member of this.arr_member){
				var counter = 0
				for(let tournament of this.group_data.arr){
					if(tournament.registration_event1.id == member.id || tournament.registration_event2.id == member.id)
						break
					counter++
				}

				if(counter == this.group_data.arr.length){
					message = member.team_name + ' (' + member.user.name + ') does not exist on any match'
					break
				}
			}

			if(message != '')
				this.base.show_error(message)
			else
				this.$emit('onSaveClicked', this.group_data)
		},
	},
};
</script>
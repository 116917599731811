<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail
        :title="competition_data.category_sport != null && competition_data.category_sport.name != null ? competition_data.category_sport.name : ''" />
    </div>
    <CompetitionTab :competition_data="competition_data" />

    <div class="my-5 pb-5 container">
      <div class="py-3">
        <p class="m-0 competition-detail-title">{{ $t('group') }}</p>

        <div class="py-3">
          <div class="form-group ">
            <select class="form-control"
              style="font-size: 0.75rem; height: 2.5rem; width: auto; background-position: right .75rem center;"
              v-model="type">
              <option value="">All</option>
              <option value="club">Sport Club</option>
              <option value="school">Academy</option>
            </select>
          </div>

          <div>
            <div v-for="(group, index) in arr_group" :key="index" class="mb-5">
              <h4 class="mb-4">{{ group.member[0].user.type.name }} / {{ group.event_category_sport_category.name }} / {{
                group.name }}</h4>
              <div v-for="(member, index1) in group.member" :key="index1" class="row">
                <div class="col-12 col-md-5 col-xl-6">
                  <div class="d-flex align-items-center">
                    <img :src="member.url_image" style="width: 6rem" />
                    <div class="ml-3">
                      <p class="m-0 font-weight-bold">{{ member.registration_event.team_name }}</p>
                      <small class="m-0">{{ member.user.name }}</small>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-7 mt-2 mt-md-0 col-xl-6">
                  <div class="opponent-container" style="gap: .5rem">
                    <div v-for="(opponent, index2) in member.arr_opponent" :key="index2"
                      class="d-flex flex-column justify-content-between">
                      <img :src="opponent.url_image"/>
                      <small class="text-center mt-1">{{ opponent.date.format('DD/MMM') }}</small>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="breakline"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'

import NoImage from '@/assets/no_image.png'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
    'CompetitionTab': CompetitionTab,
  },
  data() {
    return {
      base: null,
      arr_factor: [false, false, ],
      type: '',
      arr_group: {},
      competition_data: {},
    }
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    type() {
      this.get_data()
    },
  },
  mounted() {
    this.base = new Base()
    // window.addEventListener('scroll', this.handleScroll)
    this.arr_factor = [true,]

    this.get_competition_data()
    this.get_data()
  },
  methods: {
    async get_competition_data() {
      var response = await this.base.request(this.base.url_api + "/event/category-sport?id=" + this.$route.query.id)
      this.$set(this.arr_factor, 0, true)

      if (response != null) {
        if (response.status === "success") {
          response.data.start_date_moment = momentTZ.tz(response.data.start_date, this.base.locale_timezone)
          response.data.end_date_moment = momentTZ.tz(response.data.end_date, this.base.locale_timezone)
          response.data.event.start_school_registration_moment = momentTZ.tz(response.data.event.start_school_registration, this.base.locale_timezone)
          response.data.event.end_school_registration_moment = momentTZ.tz(response.data.event.end_school_registration, this.base.locale_timezone)
          response.data.event.start_registration_team_moment = momentTZ.tz(response.data.event.start_registration_team, this.base.locale_timezone)
          response.data.event.end_registration_team_moment = momentTZ.tz(response.data.event.end_registration_team, this.base.locale_timezone)
          response.data.event.start_payment_registration_moment = momentTZ.tz(response.data.event.start_payment_registration, this.base.locale_timezone)
          response.data.event.end_payment_registration_moment = momentTZ.tz(response.data.event.end_payment_registration, this.base.locale_timezone)
          response.data.event.coach_meeting_moment = momentTZ.tz(response.data.event.coach_meeting, this.base.locale_timezone)
          response.data.event.medal_ceremony_moment = momentTZ.tz(response.data.event.medal_ceremony, this.base.locale_timezone)

          this.competition_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_data() {
			var counter = 0
			for(let factor of this.arr_factor){
				if(!factor)
					break
				counter++
			}

			if(counter == this.arr_factor.length)
				window.$('#please_wait_modal').modal('show')

      var response = await this.base.request(this.base.url_api + '/group/all?type=' + this.type + '&event_category_sport_id=' + this.$route.query.id);
      this.$set(this.arr_factor, 1, true)

			if(counter == this.arr_factor.length)
				window.$('#please_wait_modal').modal('hide')

      if (response != null) {
        if (response.status == "success") {
          for (let group of response.data) {
            for (let member of group.member) {
              member.date = momentTZ.tz(member.match_date, this.base.locale_timezone)
              member.url_image = member.registration_event.url_image != null ? member.registration_event.url_image : NoImage
              for (let opponent of member.arr_opponent) {
                opponent.date = momentTZ.tz(opponent.match_date, this.base.locale_timezone)
                opponent.url_image = opponent.registration_event.url_image != null ? opponent.registration_event.url_image : NoImage
              }
            }
          }
          this.arr_group = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/app.scss";

@include media-breakpoint-up(md) {
  // .opponent-container {
  //   display: flex;
  //   align-items: start;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   flex-wrap: nowrap !important;
  //   overflow-x: auto;
  //   img{
  //     width: 5rem;
  //   }
  // }
}

@include media-breakpoint-down(sm) {
  // .opponent-container {
  //   display: grid;
  //   grid-template-columns: repeat(7, 1fr);
  //   grid-row: auto;
  //   img{
  //     width: 100%;
  //   }
  // }
}

.opponent-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-row: auto;
    img{
      width: 100%;
    }
  }

.competition-detail-venue-court {
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

.breakline {
  border: 1px solid $gray-300;
  margin: 1.5rem 0;
}

// .form-control{
//   background-position: right .75rem center;
// }</style>

<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail
        :title="
          competition_data.category_sport != null && competition_data.category_sport.name != null
            ? competition_data.category_sport.name
            : ''
        " />
    </div>
    <CompetitionTab :competition_data="competition_data" />

    <div class="my-5 pb-5 container">
      <div class="py-3">
        <p class="m-0 competition-detail-title">{{ $t("best_player") }}</p>

        <div class="py-3">
          <div class="d-flex">
            <div class="form-group">
              <select
                class="form-control"
                style="font-size: 0.75rem; height: 2.5rem; width: auto; background-position: right 0.75rem center"
                v-model="type">
                <option value="">All</option>
                <option value="club">Sport Club</option>
                <option value="school">Academy</option>
              </select>
            </div>
          </div>

          <div>
            <div v-if="!no_data_flag">
							
							<div v-if="arr_group.length > 0">
								<div v-for="(group, index) in arr_group" :key="index" class="mb-5">
									<h4 v-if="group.cutoff_category != null">{{ group.event_category_sport_category.name }} / Round {{ group.cutoff_category.name }}</h4>
									
									<div class="col-12 border-bottom py-4" v-if="group.best_player != null">
										<div class="d-flex justify-content-between align-items-center">
											<div class="d-flex align-items-center h-100 flex-fill" style="gap: 1rem">
												<div
													class="d-flex align-items-center justify-content-center match-image-container"
													style="width: 4rem; height: 4rem; min-width: 4rem; min-height: 4rem">
													<img
														:src="group.best_player.url_image"
														class="mw-100 mh-100" />
												</div>
												
												<p class="m-0">{{ group.best_player.back_number }}</p>
												
												<div class="topScorerPlayerDesc">
													<p class="m-0 flex-custom-1 font-weight-bold">{{ group.best_player.name }}</p>
													<p class="m-0 flex-custom-1">
														{{ group.best_player.player_position != null ? group.best_player.player_position.name : "-" }}
													</p>
													<p class="m-0 flex-custom-1 text-secondary">{{ group.best_player.registration_event.user.name }}</p>
												</div>
											</div>
											
											<div class="d-flex align-items-center h-100">
												<div class="position-relative">
													<span class="material-icons text-warning" style="font-size: 48px"> star </span>
													<span
														class="position-absolute text-primary font-weight-bold"
														style="top: 50%; left: 50%; transform: translate(-60%, -60%)">
														{{ group.best_player.total_best_player }}
													</span>
												</div>
												
												<div class="p-3 bg-warning ml-2">{{ group.best_player.total_yellow_card }}</div>
												<div class="p-3 bg-danger text-white ml-3">{{ group.best_player.total_red_card }}</div>
												<div class="p-3 ml-2">{{ group.best_player.total_score }}</div>
											</div>
										</div>
									</div>
									
								</div>
							</div>
							
							<div v-else-if="arr_group_category.length > 0">
								<div v-for="(group_category, index) in arr_group_category" :key="index" class="mb-5">
									<h4>{{ group_category.event_category_sport_category.name }}</h4>
									
									<div v-for="(group, index1) in group_category.arr" :key="index1" class="row">
										<div class="col-12 border-bottom py-4">
											<div class="d-flex justify-content-between align-items-center" style="gap: 1rem">
												
												<div class="d-flex align-items-center h-100 flex-fill" style="gap: 1rem">
													<div
														class="d-flex align-items-center justify-content-center match-image-container"
														style="width: 4rem; height: 4rem; min-width: 4rem; min-height: 4rem;">
														<img
															:src="group.best_player.url_image"
															class="mw-100 mh-100" />
													</div>
													
													<div class="text-center text-md-left mt-2 mt-md-0 ml-md-3">
														<p class="m-0 font-weight-bold">{{ group.best_player.team_name }}</p>
														<p class="m-0">{{ group.best_player.user.name }}</p>
														<p class="m-0 text-secondary">{{ competition_data.scoring_type.data != 'tournament_no_group' ? 'Round' : ''}} {{ group.cutoff_category.name }}</p>
													</div>
												</div>
												
												<div class="d-flex align-items-center h-100">
													<div class="position-relative">
														<span class="material-icons text-warning" style="font-size: 48px"> star </span>
														<span
															class="position-absolute text-primary font-weight-bold"
															style="top: 50%; left: 50%; transform: translate(-60%, -60%)">
															1
														</span>
													</div>
												</div>
												
											</div>
										</div>
									</div>
									
								</div>
							</div>
							
            </div>

            <div v-else>
              <p class="text-center">No Data Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Base from "@/utils/base";
  import momentTZ from "moment-timezone";

  import NavbarDetail from "@/layout/navbar_detail.vue";
  import CompetitionTab from "@/pages/competition/tab.vue";

  import NoImage from "@/assets/no_image.png";

  export default {
    components: {
      NavbarDetail: NavbarDetail,
      CompetitionTab: CompetitionTab,
    },
    data() {
      return {
        base: null,
        arr_factor: [false, false],
        type: "",
        category: "",
        no_data_flag: false,
        arr_group: [],
				arr_group_category: [],
        arr_event_category_sport_category: [],
        competition_data: {},
      };
    },
    watch: {
      arr_factor(val) {
        this.$emit("onChangeArrFactor", val);
        // this.manage_start_animation()
      },
      type() {
        this.get_data();
      },
      category() {
        this.get_data();
      },
			competition_data(val){
				if(val.id != null){
					if(val.scoring_type.data == 'cutoff' || val.scoring_type.data == 'cutoff_tournament')
						this.get_cutoff_data()
					else if(val.scoring_type.data == 'tournament_no_group')
						this.get_tournament_data()
					else
						this.get_data()
				}
			},
    },
    mounted() {
      this.base = new Base();
      // window.addEventListener('scroll', this.handleScroll)
      this.arr_factor = [true];

      this.get_competition_data();
      
    },
    methods: {
      async get_competition_data() {
        var response = await this.base.request(this.base.url_api + "/event/category-sport?id=" + this.$route.query.id);
        this.$set(this.arr_factor, 0, true);

        if (response != null) {
          if (response.status === "success") {
            response.data.start_date_moment = momentTZ.tz(response.data.start_date, this.base.locale_timezone);
            response.data.end_date_moment = momentTZ.tz(response.data.end_date, this.base.locale_timezone);
            response.data.event.start_school_registration_moment = momentTZ.tz(
              response.data.event.start_school_registration,
              this.base.locale_timezone
            );
            response.data.event.end_school_registration_moment = momentTZ.tz(
              response.data.event.end_school_registration,
              this.base.locale_timezone
            );
            response.data.event.start_registration_team_moment = momentTZ.tz(
              response.data.event.start_registration_team,
              this.base.locale_timezone
            );
            response.data.event.end_registration_team_moment = momentTZ.tz(
              response.data.event.end_registration_team,
              this.base.locale_timezone
            );
            response.data.event.start_payment_registration_moment = momentTZ.tz(
              response.data.event.start_payment_registration,
              this.base.locale_timezone
            );
            response.data.event.end_payment_registration_moment = momentTZ.tz(
              response.data.event.end_payment_registration,
              this.base.locale_timezone
            );
            response.data.event.coach_meeting_moment = momentTZ.tz(
              response.data.event.coach_meeting,
              this.base.locale_timezone
            );
            response.data.event.medal_ceremony_moment = momentTZ.tz(
              response.data.event.medal_ceremony,
              this.base.locale_timezone
            );

            this.competition_data = response.data;
          } else this.base.show_error(response.message);
        } else console.log(this.$t("server_error"));
      },
      async get_data() {
				var is_cutoff = this.competition_data.scoring_type.data == 'cutoff' || this.competition_data.scoring_type.data == 'cutoff_tournament'
        var response = await this.base.request(
          this.base.url_api + 
					(is_cutoff ? '/cutoff-group' : '/group') + 
					"/all?type=" + this.type + 
          "&event_category_sport_id=" + this.$route.query.id +
          '&sort=[{"name":"' + (is_cutoff ? 'best_player_total_score' : 'best_player_total') + '","sort":"desc"}]'
        );
        this.$set(this.arr_factor, 1, true);

        if (response != null) {
          if (response.status == "success") {
            var counter = 0;
            for (let group of response.data) {
              if (group.best_player != null)
                group.best_player.url_image =
                  group.best_player.file_name != null
                    ? this.base.host + "/media/registration/player?file_name=" + group.best_player.file_name
                    : NoImage;
              else counter++;
            }
            this.no_data_flag = counter == response.data.length;
            this.arr_group = response.data;
          } else this.base.show_error(response.message);
        } else console.log(this.$t("server_error"));
      },
			// async get_cutoff_data() {
			// 	var response = await this.base.request(
			// 		this.base.url_api + "/cutoff-group/all?&type=" + this.type +
			// 		"&event_category_sport_id=" + this.$route.query.id +
			// 		"&event_category_sport_category_id=" + this.category
			// 	);
			// 	this.$set(this.arr_factor, 2, true);
			// 
			// 	if (response != null) {
			// 		if (response.status == "success") {
			// 			for(let data of response.data){
			// 				
			// 			}
			// 			this.arr_group_category = response.data;
			// 		} 
			// 		else 
			// 			this.base.show_error(response.message);
			// 	} 
			// 	else 
			// 		console.log(this.$t("server_error"));
			// },
			async get_cutoff_data() {
				var response = await this.base.request(
					this.base.url_api + "/cutoff-group/event-category-sport-category?api_type=best_player&type=" + this.type +
					"&event_category_sport_id=" + this.$route.query.id +
					"&event_category_sport_category_id=" + this.category
				);
				this.$set(this.arr_factor, 2, true);
			
				if (response != null) {
					if (response.status == "success") {
						for(let data of response.data){
							for(let data1 of data.arr){
								if(data1.event_category_sport_category.event_category_sport.cutoff_scoring_type == 'timer')
									data1.timer_moment = momentTZ.duration(data1.total_score, 's')
								console.log(data1.best_player)
							}
						}
						console.log(response.data)
						this.arr_group_category = response.data;
					} else this.base.show_error(response.message);
				} else console.log(this.$t("server_error"));
			},
			async get_tournament_data() {
				var response = await this.base.request(
					this.base.url_api + "/tournament/event-category-sport-category?api_type=best_player&type=" + this.type +
					"&event_category_sport_id=" + this.$route.query.id +
					"&event_category_sport_category_id=" + this.category
				);
				this.$set(this.arr_factor, 2, true);
			
				if (response != null) {
					if (response.status == "success") {
						for(let data of response.data){
							for(let data1 of data.arr){
								if(data1.event_category_sport_category.event_category_sport.cutoff_scoring_type == 'timer')
									data1.timer_moment = momentTZ.duration(data1.total_score, 's')
								
								data1.best_player = data1
							}
						}
						console.log(response.data)
						this.arr_group_category = response.data;
					} else this.base.show_error(response.message);
				} else console.log(this.$t("server_error"));
			},
    },
  };
</script>

<style lang="scss">
  .competition-detail-venue-court {
    font-family: "montserrat-regular";
    color: $primary;
  }

  .custom-title {
    color: $primary;
    font-family: poppins-bold;
  }

  .about-us-medal-detail {
    color: $gray20;
  }

  .trust-card {
    background-color: $gray9;
    border: none;
  }

  .title-section {
    font-size: 2rem;
    font-family: poppins-medium;
  }

  .content-section {
    color: $gray6;
  }

  .team-role {
    color: $black1;
  }

  .team-name {
    color: $black1;
    font-family: poppins-bold;
  }

  .about-us-title1-enter-active,
  .about-us-title1-leave-active,
  .about-us-title2-enter-active,
  .about-us-title2-leave-active,
  .about-us-title3-enter-active,
  .about-us-title3-leave-active {
    transition: all 2s;
  }

  .about-us-title1-leave-to,
  .about-us-title1-enter,
  .about-us-title2-leave-to,
  .about-us-title2-enter,
  .about-us-title3-leave-to,
  .about-us-title3-enter {
    transform: translateX(-10rem);
    opacity: 0;
  }

  .about-us-content1-enter-active,
  .about-us-content1-leave-active,
  .about-us-content2-enter-active,
  .about-us-content2-leave-active,
  .about-us-content3-enter-active,
  .about-us-content3-leave-active {
    transition: all 2s;
  }

  .about-us-content1-leave-to,
  .about-us-content1-enter,
  .about-us-content2-leave-to,
  .about-us-content2-enter,
  .about-us-content3-leave-to,
  .about-us-content3-enter {
    transform: translateX(10rem);
    opacity: 0;
  }

  .about-us-image1-enter-active,
  .about-us-image1-leave-active,
  .about-us-image2-enter-active,
  .about-us-image2-leave-active,
  .about-us-image3-enter-active,
  .about-us-image3-leave-active {
    transition: all 2s;
  }

  .about-us-image1-leave-to,
  .about-us-image1-enter,
  .about-us-image2-leave-to,
  .about-us-image2-enter,
  .about-us-image3-leave-to,
  .about-us-image3-enter {
    // margin-left: 10rem !important;
    opacity: 0;
  }

  #about-us-content img {
    width: 100%;
    border-radius: 1rem;
  }

  .trust-list > p:nth-child(1) {
    min-height: 100px;
    text-align: center;
  }

  .trust-list > p:nth-child(2) {
    text-align: justify;
    text-align-last: center;
  }

  .table-member {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    font-size: 0.75rem;
  }

  .table-member > tr {
    display: table;
    width: 100%;
  }

  .btn .caret {
    position: absolute;
    top: calc(50% - 1px);
  }

  // .form-control{
  //   background-position: right .75rem center;
  // }
</style>
<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail :title="$t('school_academy')" />
    </div>

    <div class="mt-5 pb-5 container">
      <div class="form-group ">
        <select class="form-control"
          style="font-size: 0.75rem; height: 2.5rem; width: auto; background-position: right .75rem center;" v-model="type">
          <option value="club">Sport Club</option>
          <option value="school">Academy</option>
        </select>
      </div>

      <div v-if="arr_school.length > 0">
        <table class="table table-member table-striped">
          <tr v-for="(school, index) in arr_school" :key="index">
            <td class="w-50">
              <div class="d-flex align-items-center">
                <img :src="school.image" style="width: 2.5rem;" />
                <span class="pl-3 font-weight-bold" style="color: #015057;">
                  {{ school.name }}
                </span>
              </div>
            </td>
            <!-- <td class="align-middle">{{ school.category.name }}</td>
            <td class="align-middle">{{ school.province.name }}</td> -->
            <td class="align-middle w-50">{{ school.city != null ? school.city.name : '-' }}</td>
            <td class="align-middle actionDiv" style="width: 1%; white-space: nowrap;">
              <!-- <button type="button" class="btn btn-secondary" data-container="body" data-trigger="focus" data-toggle="popover" data-placement="left" data-content="Top popover">
                Popover on top
              </button> -->
              <div class="position-relative">
                <div class="popover-container position-absolute py-2 px-2 rounded" style="left: -7rem; top: -.5rem;" v-show="school.action_selected">
                  <a target="_blank" :href="school.phone != null ? 'tel:' + school.phone : '-'">
                    <img src="@/assets/member_call.png" class="popover-item-image">
                  </a>
                  <a target="_blank" :href="school.phone != null ? 'https://wa.me/' + school.phone : '-'">
                    <img src="@/assets/member_chat.png" class="popover-item-image">
                  </a>
                  <a target="_blank" :href="school.email != null ? 'mailto:' + school.email : '-'">
                    <img src="@/assets/member_mail.png" class="popover-item-image">
                  </a>
                </div>

                <a @click="onActionClicked(index)" rel="popover" role="button" tabindex="1">
                  <img width="24" height="24" src="https://img.icons8.com/ios/24/015057/menu-2.png" alt="menu-2" />
                </a>
              </div>
              <!-- <img src="@/assets/member_call.png" style="height: 1.25rem;"> -->
              <!-- <a href="#" class="ml-3">
                <img src="@/assets/member_chat.png" style="height: 1.25rem;">
              </a>
              <a href="#" class="ml-3">
                <img src="@/assets/member_mail.png" style="height: 1.25rem;">
              </a> -->
            </td>

            <!-- <div class="" hidden>
              <div data-name="popover-content">
                <div class="input-group">
                  <input type="text" class="form-control form-control-sm" placeholder="Search" name="search">
                  <div class="input-group-btn">
                    <button class="btn btn-danger" type="submit">
                      <i class="bi bi-search fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div> -->
          </tr>
        </table>

        <pagination v-model="page" :records="total_data" @paginate="onPaginate" class="text-right" :options="pagination_option"/>
      </div>
      <div v-else>
        <p class="m-0 text-center">{{ $t('no_data_found') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import $ from 'jquery'

import NavbarDetail from '@/layout/navbar_detail.vue'

import NoImage from '@/assets/no_image.png'
// import MemberCall from '@/assets/member_call.png'
// import MemberChat from '@/assets/member_chat.png'
// import MemberMail from '@/assets/member_mail.png'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
  },
  data() {
    return {
      base: null,
      type: 'club',
      arr_factor: [false,],
      arr_school: [
      ],
      page: 1,
      total_data: 0,
      pagination_option: {
        texts: {
          count: '',
        },
      },
    }
  },
  watch: {
    arr_factor(val){
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    scrollY(){
      // this.manage_start_animation()
    },
    type(){
      this.get_user()
    },
  },
  created() {
    this.base = new Base()
    
    // this.arr_factor = [true,]
    this.get_user()
  },
  methods: {
    onPaginate(){
      this.get_user()
    },
    onActionClicked(index){
      var arr_school = JSON.parse(JSON.stringify(this.arr_school))
      arr_school[index].action_selected = !arr_school[index].action_selected
      for (let x in arr_school){
        if(x != index)
          arr_school[x].action_selected = false
      }
      
      this.arr_school = arr_school
    },
    async get_user() {
      if(this.arr_factor[0])
        window.$('#please_wait_modal').modal('show')
      var response = await this.base.request(this.base.url_api + "/user?page=" + this.page + "&type_name=" + this.type)
      this.$set(this.arr_factor, 0, true)

      if(this.arr_factor[0])
        window.$('#please_wait_modal').modal('hide')
      if (response != null) {
        if (response.status === "success") {
          for (let user of response.data) {
            user.image = user.file_name != null ? this.base.host + "/media/section/user?file_name=" + user.file_name : NoImage
            user.action_selected = false
          }
          this.total_data = response.recordsTotal
          this.arr_school = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
select {
  appearance: none;
  // background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  // background-repeat: no-repeat;
  // background-position: right 1rem center;
  // background-size: 1em;
  padding-right: 2.5rem !important;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

.popover-container {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  border: 1px solid $gray-300;
}

.popover-item-image {
  height: 1.25rem;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

// .form-control{
//   background-position: right .75rem center;
// }
</style>

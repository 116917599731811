<template>
  <div class="">
    <div class="m-3 d-flex justify-content-between">
      <a href="#" @click="onBackClicked">Back</a>
      <div v-show="user != null && user.type != null && user.type.name == 'coordinator' && match.allow_edit">
        <!-- <a href="#" @click="onAddEventClicked" v-show="!match.is_finished">Add Match Event</a> -->
        <a href="#" @click="onSetFinishClicked" class="ml-3" v-show="group_data.event_category_sport_category != null && ((group.event_category_sport_category.event_category_sport.scoring_type.data != 'manual' && !match.is_finished) || group.event_category_sport_category.event_category_sport.scoring_type.data == 'manual')">Set Finish</a>
      </div>
    </div>

    <div class="my-5 pb-5 container " v-if="group_data.event_category_sport_category != null">
      <div class="py-3">
        <div class="border-bottom py-4">

					<div class="d-flex justify-content-between align-items-center">
						<p class="m-0 font-weight-bold">{{ group_data.type != null ? group_data.type.name + ' /' : '' }} {{ group_data.event_category_sport_category.name }}</p>

						<div class="d-flex align-items-center">
							<p class="m-0 font-weight-bold">{{ group_data.member.length }} / {{ group_data.cutoff_category.name }}</p>
						</div>
					</div>

					<table class="table ladder-table mt-3">
						<thead>
							<tr>
								<th style="width: 40%">School / Team</th>
								<th class="text-center">{{ group_data.event_category_sport_category.event_category_sport.cutoff_match_type == 'multiple' ? 'Round ' + group_data.cutoff_category.name : 'Single' }}</th>
								<th class="text-center">Attendance</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(member, index) in group_data.member" :key="index">

								<td>
									<div class="d-flex align-items-center h-100">

										<div class="d-flex align-items-center justify-content-center" style="height: 5rem; width: 5rem;">
											<img :src="member.registration_event.url_image" style="max-height: 100%; width: auto; max-width: 100%;" />
										</div>

										<div class="ml-3">
											<p class="m-0 font-weight-bold">{{ member.registration_event.team_name }}</p>
											<p class="m-0">{{ member.registration_event.user.name }}</p>
										</div>

									</div>
								</td>

								<td class="text-center">
									<div class="d-flex justify-content-center align-items-center">

										<div v-show="group_data.event_category_sport_category != null && group_data.event_category_sport_category.event_category_sport.scoring_type.data != 'manual' && !match.is_finished">
											<button class="btn btn-primary mr-3" v-if="user != null && user.type != null && user.type.name == 'coordinator'" @click="onEditScore(index)">
												Edit {{ group_data.event_category_sport_category.event_category_sport.cutoff_scoring_type == 'timer' ? 'Time' : 'Score' }}
											</button>
										</div>

										<div v-if="member.current_cutoff_category.match_event != null">

											<p class="m-0 text-center" v-if="group.event_category_sport_category.event_category_sport.scoring_type.data != 'manual'">
												{{
													group_data.event_category_sport_category.event_category_sport.cutoff_scoring_type == 'timer' ?
														(
															(member.current_cutoff_category.match_event.timer_moment.hours() < 10 ? '0' + member.current_cutoff_category.match_event.timer_moment.hours() : member.current_cutoff_category.match_event.timer_moment.hours()) + ':' +
															(member.current_cutoff_category.match_event.timer_moment.minutes() < 10 ? '0' + member.current_cutoff_category.match_event.timer_moment.minutes() : member.current_cutoff_category.match_event.timer_moment.minutes()) + ':' +
															(member.current_cutoff_category.match_event.timer_moment.seconds() < 10 ? '0' + (member.current_cutoff_category.match_event.timer_moment.seconds()) : member.current_cutoff_category.match_event.timer_moment.seconds()) + '.' +
															(member.current_cutoff_category.match_event.timer_moment.milliseconds() < 10 ?  '00' + (member.current_cutoff_category.match_event.timer_moment.milliseconds())  : member.current_cutoff_category.match_event.timer_moment.milliseconds() < 100 ? '0' + member.current_cutoff_category.match_event.timer_moment.milliseconds() : member.current_cutoff_category.match_event.timer_moment.milliseconds())
														)
													:
													member.current_cutoff_category.match_event.total_score.toLocaleString(base.locale_string)
												}}
											</p>

											<p v-else>{{ $t('place_' + member.current_cutoff_category.match_event.rank.toLocaleString(base.locale_string)) }}</p>

										</div>

										<div v-else>
											<p class="m-0" v-if="group.event_category_sport_category.event_category_sport.scoring_type.data != 'manual'">
												No {{ group_data.event_category_sport_category.event_category_sport.cutoff_scoring_type == 'timer' ? 'Time' : 'Score' }}
											</p>

											<p v-else>Participant</p>
										</div>

									</div>
								</td>

								<td class="text-center">
									<input
										class=""
										type="checkbox"
										:value="true"
										:checked="member.current_cutoff_category.match_attendance != null"
										:disabled="match.is_finished"
										@change="onAttendanceChanged(index)"
										id="defaultCheck1"/>
								</td>

							</tr>
						</tbody>
					</table>

				</div>

        <div class="breakline"></div>

        <div class="mt-3">
          <!-- <div class="d-flex justify-content-between">
            <p class="m-0">Best Player</p>
            <p class="m-0">{{ group_data.match != null && group_data.match.best_player != null ? group_data.match.best_player.name : '-' }}</p>
          </div> -->

          <div class="d-flex justify-content-between mt-3">
            <p class="m-0">Competition Coordinator</p>
            <p class="m-0">{{ match != null ? match.str_coordinator : '-' }}</p>
          </div>

          <div class="d-flex justify-content-between mt-3">
            <p class="m-0">Referee</p>
            <p class="m-0">{{ match != null && match.referee != null ? match.referee : '-' }}</p>
          </div>

          <div class="d-flex justify-content-between mt-3">
            <p class="m-0">Judge 1</p>
            <p class="m-0">{{ match != null && match.judge1 != null ? match.judge1 : '-' }}</p>
          </div>

          <div class="d-flex justify-content-between mt-3">
            <p class="m-0">Judge 2</p>
            <p class="m-0">{{ match != null && match.judge2 != null ? match.judge2 : '-' }}</p>
          </div>
        </div>

        <div class="breakline"></div>
      </div>
    </div>

    <div v-if="user != null && user.type != null && user.type.name == 'coordinator'">
      <AddMatchEventCutoff :event_data="selected_event" :arr_match_event_manual="arr_match_event_manual" :event_category_sport="selected_event_category_sport" @onForceReload="onForceReload" :member="selected_member" :match_data="match"/>
      <AddBestPlayer :match_data="match" @onForceReload="onForceReload" @onMatchFinished="onMatchFinished"/>
			<AddManualWinner :match_data="match" @onForceReload="onForceReload" @onMatchFinished="onMatchFinished"/>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'
import AddMatchEvent from '@/pages/competition/add_match_event.vue'
import AddMatchEventCutoff from '@/pages/competition/add_match_event_cutoff.vue'
import AddBestPlayer from '@/pages/competition/add_best_player.vue'
import AddManualWinner from '@/pages/competition/add_manual_winner.vue'

import MatchEvent from '@/pages/competition/component/match_event.vue'
import MatchEventCutoff from '@/pages/competition/component/match_event_cutoff.vue'
import MatchEventUser from '@/pages/competition/component/match_event_user.vue'
import PlayerAttendance from '@/pages/competition/component/player_attendance.vue'
import PlayerAttendanceCutoff from '@/pages/competition/component/player_attendance_cutoff.vue'

import NoImage from '@/assets/no_image.png'

export default {
  props: ['group', 'user_data',],
  components: {
    'AddMatchEventCutoff': AddMatchEventCutoff,
    'AddBestPlayer': AddBestPlayer,
		'AddManualWinner': AddManualWinner,
  },
  data() {
    return {
      base: null,
      arr_factor: [false,],
      date: '',
      match: {},
      arr_match_event: [],
      arr_member1_player: [],
      arr_member2_player: [],
      competition_data: {},
      user: {},
      selected_event: {},
			selected_member: {},
			selected_event_category_sport: {},
      type: 'player_attendance',
      rnd_time: momentTZ(),
			isFinished: false,
			group_data: {},
			group_data1: {},
			arr_match_event_manual: [],
    }
  },
  watch: {
		async group(val){
			if(val.event_category_sport_category != null){
				await this.get_data()
				this.get_match_data()

				// if(val.match.arr_match_event != null)
				// 	this.arr_match_event_manual = val.match.arr_match_event
			}
		},
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    match_data(val) {
      this.onForceReload()
    },
    user(val) {
      this.onForceReload()
    },
    user_data(val) {
      if (val == null || val.type == null || (val.type != null && val.type.name != 'coordinator'))
        this.type = 'match_event_user'
    },
  },
  async created() {
    this.base = new Base()
    // window.addEventListener('scroll', this.handleScroll)
    this.arr_factor = [true,]

    var user = await window.localStorage.getItem('user')
    this.user = JSON.parse(user)
  },
  methods: {
    async onEditScore(index) {
			var cutoff_category = this.group_data.member[index].current_cutoff_category
      this.selected_event = cutoff_category.match_event != null ? cutoff_category.match_event : {}
			this.selected_member = this.group_data.member[index]
			this.match = cutoff_category.match
			this.selected_event_category_sport = this.group_data.event_category_sport_category.event_category_sport

      window.$('#addMatchEventModal').modal('show')
    },
    onPlayerAttendanceClicked() {
      this.type = 'player_attendance'
    },
    onMatchEventClicked() {
      this.type = 'match_event'
    },
    onBackClicked() {
      // if(this.type == 'player_attendance')
      //   this.type = 'match_event'
      // else
      this.$emit('onBackClicked', this.isFinished)
			this.isFinished = false
    },
		onMatchFinished(isFinished){
			this.isFinished = isFinished
		},
    async onForceReload() {
      if (this.group_data.id != null) {
				if(this.isFinished){
					this.$emit('onBackClicked', this.isFinished)
					this.isFinished = false
				}
				else{
					this.get_data()
					this.get_match_data()
				}
      }
    },
    onEventClicked(index) {
      if (this.user.type.name == 'coordinator') {
        this.selected_event = this.arr_match_event[index]
        window.$('#addMatchEventModal').modal('show')
      }
    },
		onEventCutoffClicked(registration) {
			if (this.user.type.name == 'coordinator' && registration.match_event != null) {
				this.selected_event = registration.match_event
				window.$('#addMatchEventModal').modal('show')
			}
		},
    async onSetFinishClicked() {
			if(this.group_data.event_category_sport_category.event_category_sport.scoring_type.data == 'manual')
				window.$('#addManualWinnerModal').modal('show')
			else
				window.$('#addBestPlayerModal').modal('show')

      // window.$('#please_wait_modal').modal('show')
      // var response = await this.base.request(this.base.url_api + "/match/finish", 'post', {
      //   id: this.match_data.id,
      // })

      // setTimeout(() => {
      //   window.$('#please_wait_modal').modal('hide')
      // }, 500)
      // if (response != null) {
      //   if (response.status === "success") {
      //     this.onForceReload()
      //   }
      //   else
      //     this.base.show_error(response.message)
      // }
      // else
      //   console.log(this.$t('server_error'))
    },
    async onRemoveEventClicked(index) {
      if (confirm('Are you sure?')) {
        var selected_event = this.arr_match_event[index]

        window.$('#please_wait_modal').modal('show')
        var response = await this.base.request(this.base.url_api + "/match/event/delete?id=" + selected_event.id)

        setTimeout(() => {
          window.$('#please_wait_modal').modal('hide')
        }, 500)
        if (response != null) {
          if (response.status === "success") {
            this.onForceReload()
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    },
		async get_data() {
			window.$('#please_wait_modal').modal('show')

			var token = await window.localStorage.getItem('token')
			var response = await this.base.request(
				this.base.url_api + '/cutoff-group/last-group' + (token != null ? '/auth' : '') + '?api_type=last_group' +
				'&event_category_sport_id=' + this.group.event_category_sport_category.event_category_sport.id +
				'&event_category_sport_category_id=' + this.group.event_category_sport_category.id +
				(this.group.type != null ? '&type_id=' + this.group.type.id : '')
			)

			setTimeout(() => {
				window.$('#please_wait_modal').modal('hide')
			}, 500)


			if (response != null) {
				if (response.status == "success") {
					for(let member of response.data.member){
						for(let cutoff_category of member.arr_cutoff_category){
							if(response.data.event_category_sport.cutoff_scoring_type == 'timer' && cutoff_category.match_event != null){
								var temp = cutoff_category.match_event.total_score.toLocaleString().split('.')[1]
								cutoff_category.match_event.timer_moment = momentTZ.duration({
									seconds: parseInt(cutoff_category.match_event.total_score),
									milliseconds: temp != null ? (temp.length == 1 ? temp + '00' : temp.length == 2 ? temp + '0' : temp) : '0',
								})
								if(member.id == 'CUTOFF_GROUP_MEMBER_20241109_000166')
									console.log(cutoff_category.match_event.timer_moment)
							}
							if(response.data.cutoff_category.id == cutoff_category.id)
								member.current_cutoff_category = cutoff_category
						}
					}

					if(response.data.event_category_sport_category.event_category_sport.scoring_type.data == 'manual')
						response.data.member.sort((a, b) => {
							if(a.current_cutoff_category.match_event != null && b.current_cutoff_category.match_event != null)
								return a.current_cutoff_category.match_event.rank - b.current_cutoff_category.match_event.rank
							else if(a.current_cutoff_category.match_event != null && b.current_cutoff_category.match_event == null)
								return -1
							else if(a.current_cutoff_category.match_event == null && b.current_cutoff_category.match_event != null)
								return 1
							else if(a.current_cutoff_category.match_event == null && b.current_cutoff_category.match_event == null)
								return 0
						})

					this.group_data = response.data
				}
				else
					this.base.show_error(response.message)
			}
			else
				console.log(this.$t('server_error'))
		},
		async get_match_data() {
			window.$('#please_wait_modal').modal('show')

			var token = await window.localStorage.getItem('token')
			var response = await this.base.request(this.base.url_api + '/match?cutoff_group_id=' + this.group_data.id)

			setTimeout(() => {
				window.$('#please_wait_modal').modal('hide')
			}, 500)


			if (response != null) {
				if (response.status == "success") {
					var str_coordinator = ''
					var allow_edit = false
					var arr_coordinator = response.data.cutoff_group.event_category_sport.coordinator
					for (let x in arr_coordinator) {
						var coordinator = arr_coordinator[x]

						str_coordinator += coordinator.name
						if (x < arr_coordinator.length - 1)
							str_coordinator += ', '
						if (coordinator.coordinator != null) {

							if (this.user != null && coordinator.coordinator.id == this.user.id) {
								allow_edit = true
							}
						}
					}
					response.data.allow_edit = allow_edit
					response.data.str_coordinator = str_coordinator

					this.match = response.data
				}
				else
					this.base.show_error(response.message)
			}
			else
				console.log(this.$t('server_error'))
		},
    async onAttendanceChanged(index) {

			var response = await this.base.request(
				this.base.url_api + "/match/attendance",
				"post",
				{
					match_id: this.match.id,
					registration_event: {
						id: this.group_data.member[index].registration_event.id,
					},
				}
			);

			if (response != null) {
				if (response.status == "success") {
					var group_data = this.group_data
					group_data.member[index].current_cutoff_category.match_attendance = {}
					this.group_data = group_data
				} else this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
  }
}
</script>

<style lang="scss">
.competition-detail-venue-court {
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

.match-grid-1 {
  grid-area: 1 / 1 / 2 / 2;
}

.match-grid-2 {
  grid-area: 1 / 2 / 2 / 3;
}

.match-grid-3 {
  grid-area: 1 / 3 / 2 / 4;
}

.match-grid-4 {
  grid-area: 2 / 1 / 3 / 2;
  z-index: 2;
  margin: 1rem 0;
}

.match-grid-5 {
  grid-area: 2 / 2 / 3 / 3;
  z-index: 2;
  margin: 1rem 0;
}

.match-grid-6 {
  grid-area: 2 / 3 / 3 / 4;
  z-index: 2;
  margin: 1rem 0;
}

.match-grid-7 {
  grid-area: 2 / 1 / 3 / 4;
  z-index: 1;
  position: relative;

  &:after {
    content: "";
    /* width: 100vw; */
    height: 100%;
    background-color: var(--primary);
    position: absolute;
    /* margin: 0 -100vw; */
    left: -50vw;
    // right: 50vw;
    padding: 0 100vw;
    // margin-left: -50vw;
  }
}

// .match-grid-7{
//   grid-area: 2 / 1 / 3 / 4;

// }

// .form-control{
//   background-position: right .75rem center;
// }</style>

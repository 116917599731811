<template>
  <div>
		<div v-if="match.cutoff_group != null">
			<div class="row">
				<div class="col-3 mb-3" v-for="(registration, index) in arr_registration" :key="index">

					<div class="d-flex align-items-center justify-content-center justify-content-md-start flex-column flex-md-row member-container">
						<div class="d-flex align-items-center justify-content-center match-image-container">
							<img :src="registration.url_image"/>
						</div>
						<div class="text-center text-md-left mt-2 mt-md-0 ml-md-3">
							<p class="m-0 font-weight-bold">{{ registration.user.name }}</p>
							<p class="m-0">{{ registration.team_name }}</p>
							<p class="m-0 text-secondary">{{ registration.jersey_team }}</p>
						</div>
					</div>

					<div class="d-flex flex-column my-4" style="gap: 1.5rem">
						<div
							v-for="(player, index1) in registration.player"
							:key="index1"
						>
							<div class="d-flex flex-row-reverse" style="gap: .5rem">

								<div class="d-flex align-items-center justify-content-center">
									<input
										class=""
										type="checkbox"
										:value="true"
										:checked="player.is_selected"
										:disabled="player.is_selected"
										v-show="match.status != 'finished'"
										@change="onAttendanceCutoffChanged(index, index1)"
										id="defaultCheck1"
									/>

									<div v-show="match.status == 'finished'">
										<p class="m-0" v-show="!player.is_selected">Not Attended</p>
										<p class="m-0" v-show="player.is_selected">Attended</p>
									</div>
								</div>

								<div class="d-flex flex-column flex-md-row flex-fill align-items-start align-items-md-center" style="gap: .5rem">
									<div
										class="d-flex align-items-center justify-content-center"
										style="width: 4rem; height: 4rem"
									>
										<img :src="player.url_image" class="mw-100 mh-100" />
									</div>

									<div class="d-flex align-items-baseline flex-fill" style="gap: .5rem">
										<p class="m-0 text-center" style="min-width: 2rem;">{{ player.back_number }}</p>

										<div class="d-flex flex-column align-items-start">
											<p class="m-0 font-weight-bold text-primary">{{ player.name }}</p>

											<small class="m-0">
												{{
													player.player_position != null
														? player.player_position.name
														: "-"
												}}
											</small>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else>
			<div v-if="arr_player1_data.length > 0 && !is_first_time" class="d-flex flex-column my-4" style="gap: 1.5rem">
				<div
					v-for="(player, index) in arr_player1_data"
					:key="index"
					class="playerAttendanceGrid"
				>
					<div class="d-flex flex-row-reverse" style="gap: .5rem">
						<div class="d-flex align-items-center justify-content-center">
							<input
								class=""
								type="checkbox"
								:value="true"
								:checked="player.is_selected"
								:disabled="player.is_selected || match.status == 'finished'"
								@change="onAttendanceChanged(index, 'player1')"
								id="defaultCheck1"
							/>
						</div>
						<div class="d-flex flex-column flex-md-row flex-fill align-items-start align-items-md-center" style="gap: .5rem">
							<div
								class="d-flex align-items-center justify-content-center"
								style="width: 4rem; height: 4rem"
							>
								<img :src="player.url_image" class="mw-100 mh-100" />
							</div>

							<div class="d-flex align-items-baseline flex-fill" style="gap: .5rem">
								<p class="m-0 text-center" style="min-width: 2rem;">{{ player.back_number }}</p>
								<div class="d-flex flex-column align-items-start">
									<p class="m-0 font-weight-bold text-primary">{{ player.name }}</p>
									<small class="m-0">
										{{
											player.player_position != null
												? player.player_position.name
												: "-"
										}}
									</small>
								</div>
							</div>
						</div>
					</div>

					<div
						class="d-flex flex-row"
						style="gap: .5rem"
						v-if="arr_player2_data[index] != null"
					>
						<div class="d-flex align-items-center justify-content-center">
							<input
								class=""
								type="checkbox"
								:value="true"
								:checked="arr_player2_data[index].is_selected"
								:disabled="arr_player2_data[index].is_selected || match.status == 'finished'"
								@change="onAttendanceChanged(index, 'player2')"
								id="defaultCheck1"
							/>
						</div>
						<div class="d-flex flex-column flex-md-row-reverse flex-fill align-items-end align-items-md-center" style="gap: .5rem">
							<div
								class="d-flex align-items-center justify-content-center"
								style="width: 4rem; height: 4rem"
							>
								<img :src="arr_player2_data[index].url_image" class="mw-100 mh-100" />
							</div>

							<div class="d-flex flex-row-reverse align-items-baseline flex-fill" style="gap: .5rem">
								<p class="m-0 text-center" style="min-width: 2rem;">{{ arr_player2_data[index].back_number }}</p>
								<div class="d-flex flex-column align-items-end">
									<p class="m-0 font-weight-bold text-right text-primary">{{ arr_player2_data[index].name }}</p>
									<small class="m-0 text-right">
										{{
											arr_player2_data[index].player_position != null
												? arr_player2_data[index].player_position.name
												: "-"
										}}
									</small>
								</div>
							</div>
						</div>
					</div>

					<!-- <div class="row mb-3">
						<div class="col-6">
							<div class="d-flex align-items-center">
								<div
									class="d-flex justify-content-between align-items-center w-100"
								>
									<div class="d-flex align-items-center">
										<img :src="player.url_image" style="width: 5rem" />
										<p class="m-0">{{ player.back_number }}</p>
										<div class="ml-3">
											<p class="m-0">{{ player.name }}</p>
											<p class="m-0">
												{{
													player.player_position != null
														? player.player_position.name
														: "-"
												}}
											</p>
										</div>
									</div>

									<div class="d-flex">
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												:value="true"
												:checked="player.is_selected"
												:disabled="player.is_selected"
												@change="onAttendanceChanged(index, 'player1')"
												id="defaultCheck1"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-6">
							<div
								class="d-flex align-items-center"
								v-if="arr_player2_data[index] != null"
							>
								<div
									class="d-flex justify-content-between align-items-center w-100"
								>
									<div class="d-flex">
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												:value="true"
												:checked="arr_player2_data[index].is_selected"
												:disabled="arr_player2_data[index].is_selected"
												@change="onAttendanceChanged(index, 'player2')"
												id="defaultCheck1"
											/>
										</div>
									</div>

									<div class="d-flex align-items-center">
										<div class="text-right">
											<p class="m-0">{{ arr_player2_data[index].name }}</p>
											<p class="m-0">
												{{
													arr_player2_data[index].player_position != null
														? arr_player2_data[index].player_position.name
														: "-"
												}}
											</p>
										</div>
										<p class="m-0 ml-3">
											{{ arr_player2_data[index].back_number }}
										</p>
										<img
											:src="arr_player2_data[index].url_image"
											style="width: 5rem"
											class="ml-3"
										/>
									</div>
								</div>
							</div>
						</div>
					</div> -->
				</div>
			</div>

			<div v-else>
				<p class="text-center">No Data Found</p>
			</div>
		</div>
  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";

export default {
  props: ["arr_player1", "arr_player2", "match", 'user', 'rnd_time', ],
  components: {},
  data() {
    return {
      base: null,
      arr_player1_data: [],
      arr_player2_data: [],
			arr_registration: [],
      is_first_time: true,
    };
  },
  watch: {
    arr_player1(val) {
      if (val != null) this.arr_player1_data = val;
    },
    arr_player2(val) {
      if (val != null) this.arr_player2_data = val;
    },
    arr_player1_data(val) {},
    arr_player2_data(val) {},
    rnd_time(val){
      this.load_data()
    },
    match(val){
      this.load_data();
    },
  },
  async created() {
    this.base = new Base();

		if(this.match.cutoff_group != null){
			var arr_registration = []
			for(let member of this.match.cutoff_group.member){
				for(let player of member.registration_event.player){
					player.is_selected = false
				}
				arr_registration.push(member.registration_event)
			}
			this.arr_registration = arr_registration
		}
    this.arr_player1_data = JSON.parse(JSON.stringify(this.arr_player1));
    this.arr_player2_data = JSON.parse(JSON.stringify(this.arr_player2));
    this.load_data();
  },
  methods: {
    load_data(){
      if(this.match != null && this.match.id != null)
        this.get_attendance_data()
    },
    async get_attendance_data() {
			window.$('#please_wait_modal').modal('show')
      var response = await this.base.request(
        this.base.url_api + "/match/attendance/all?match_id=" + this.match.id
      );

			setTimeout(() => {
				window.$('#please_wait_modal').modal('hide')
			}, 500)

      if (response != null) {
        if (response.status == "success") {
					if(this.match.cutoff_group != null){
						var arr_registration = JSON.parse(JSON.stringify(this.arr_registration))
						for(let registration of arr_registration){
							for(let player of registration.player){
								player.is_selected = false
								for (let data of response.data) {
									if (data.registration_event_player.id == player.id) {
										player.is_selected = true
										break;
									}
								}

							}
						}
						this.arr_registration = arr_registration
					}
					else{
						var arr_player1_data = JSON.parse(
							JSON.stringify(this.arr_player1_data)
						);
						var arr_player2_data = JSON.parse(
							JSON.stringify(this.arr_player2_data)
						);
						for (let player of arr_player1_data) {
							var is_selected = false;
							for (let data of response.data) {
								if (data.registration_event_player.id == player.id) {
									is_selected = true;
									break;
								}
							}
							player.is_selected = is_selected;
						}

						for (let player of arr_player2_data) {
							is_selected = false;
							for (let data of response.data) {
								if (data.registration_event_player.id == player.id) {
									is_selected = true;
									break;
								}
							}
							player.is_selected = is_selected;
						}

						this.arr_player1_data = arr_player1_data;
						this.arr_player2_data = arr_player2_data;
					}
          this.is_first_time = false
        } else this.base.show_error(response.message);
      } else console.log(this.$t("server_error"));
    },
    async onAttendanceChanged(index, type) {
      var arr = type == "player1" ? this.arr_player1_data : this.arr_player2_data;

      var response = await this.base.request(
        this.base.url_api + "/match/attendance",
        "post",
        {
          match_id: this.match.id,
          registration_event_player: {
            id: arr[index].id,
          },
					registration_event: {
						id: arr[index].registration_event_id,
					}
        }
      );

      if (response != null) {
        if (response.status == "success") {
          var arr_temp = JSON.parse(JSON.stringify(arr));
          arr_temp[index].is_selected = true;
          if (type == "player1") this.arr_player1_data = arr_temp;
          else if (type == "player2") this.arr_player2_data = arr_temp;
        } else this.base.show_error(response.message);
      } else console.log(this.$t("server_error"));
    },
		async onAttendanceCutoffChanged(index, index1) {

			var response = await this.base.request(
				this.base.url_api + "/match/attendance",
				"post",
				{
					match_id: this.match.id,
					registration_event_player: {
						id: this.arr_registration[index].player[index1].id,
					},
				}
			);

			if (response != null) {
				if (response.status == "success") {
					var arr_registration = JSON.parse(JSON.stringify(this.arr_registration));
					arr_registration[index].player[index1].is_selected = true
					this.arr_registration = arr_registration
				} else this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
  },
};
</script>

<style lang="scss">
.playerAttendanceGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
</style>

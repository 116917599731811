<template>
  <div class="">
    <p class="m-0">{{ $t('summary_payment') }}</p>

    <div class="card mt-4">
      <div class="card-body">
        <div>
          <p class="m-0">{{ $t('sport_category') }}</p>
          <p class="m-0">{{ event_category_sport_data.category_sport != null ? event_category_sport_data.category_sport.name : '' }}</p>
          <p class="m-0">{{ event_category_sport_data.event != null && event_category_sport_data.event.school_registration_moment != null ? event_category_sport_data.event.school_registration_moment.format('DD/MM/YYYY') : '' }}</p>
        </div>

        <div class="mt-4">
          <p class="m-0">{{ $t('registered_team') }}</p>
          <div v-if="is_many_team">
            <div v-if="arr_payment_team.length > 0">
              <div class="d-flex justify-content-between" v-for="(team, index) in arr_payment_team" :key="index">
                <p class="m-0">{{ team.category.name }}</p>
                <p class="m-0">Rp. {{ team.category.fee_registration.toLocaleString(base.locale_string) }}</p>
              </div>
            </div>
            <div class="d-flex justify-content-center" v-else>
              <p class="m-0">{{ $t('no_data_found') }}</p>
            </div>
          </div>
          <div v-else>
            <div v-if="category_team.name != null">
              <div class="d-flex justify-content-between">
                <p class="m-0">{{ category_team.name }}</p>
                <p class="m-0">Rp. {{ category_team.fee_registration.toLocaleString(base.locale_string) }}</p>
              </div>
            </div>
            <div class="d-flex justify-content-center" v-else>
              <p class="m-0">{{ $t('no_data_found') }}</p>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <div class="d-flex justify-content-between">
            <p class="m-0">{{ $t('subtotal') }}</p>
            <p class="m-0">Rp. {{ subtotal.toLocaleString(base.locale_string) }}</p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="m-0">{{ $t('service_charge') }}</p>
            <p class="m-0">Rp. {{ admin_fee.toLocaleString(base.locale_string) }}</p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="m-0">{{ $t('total') }}</p>
            <p class="m-0">Rp. {{ total.toLocaleString(base.locale_string) }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <div v-for="(button, index) in arr_button" :key="index">
        <button class="btn btn-primary text-warning w-100" @click="$emit('onButtonClicked', index)">{{ $t(button.name_data) }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

export default {
  components: {
  },
  props: ['arr_team', 'is_many_team', 'category_team', 'arr_button', ],
  data(){
    return{
      base: null,
      scrollY: 0,
      arr_factor: [false, false, false, false, ],
      flag: {
        aboutUsTitle1Flag: false,
        aboutUsImage1Flag: false,
        aboutUsContent1Flag: false,
        aboutUsTitle2Flag: false,
        aboutUsImage2Flag: false,
        aboutUsContent2Flag: false,
        aboutUsTitle3Flag: false,
        aboutUsImage3Flag: false,
        aboutUsContent3Flag: false,
      },
      arr_payment_team: [],
      event_category_sport_data: {},
      subtotal: 0,
      admin_fee: 0,
      total: 0,
    }
  },
  watch: {
    arr_factor(val){
      this.$emit('onChangeArrFactor', val)
      this.manage_start_animation()
    },
    scrollY(){
      this.manage_start_animation()
    },
    arr_team: {
      handler: function() {
        this.manage_payment_team()
      },
      deep: true,
    },
    arr_payment_team: {
      handler: function(val) {
        if(val.length > 0){
          var subtotal = 0
          var admin_fee = 0
          for(let payment of val){
            subtotal += payment.category.fee_registration
          }
          this.subtotal = subtotal
          this.admin_fee = admin_fee
          this.total = subtotal + admin_fee
        }
      },
      deep: true,
    },
    category_team(val){
      if(val.fee_registration != null){
        var subtotal = 0
        var admin_fee = 0
        subtotal += val.fee_registration

        this.subtotal = subtotal
        this.admin_fee = admin_fee
        this.total = subtotal + admin_fee
      }
    },
  },
  created(){
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1
    this.arr_factor = [true, true, true, true, ]

    this.manage_payment_team()
  },
  methods: {
    manage_payment_team(){
      if(this.arr_team != null){
        var arr = []
        for(let team of this.arr_team){
          if(team.category_id != "")
            arr.push(team)
        }
        this.arr_payment_team = arr
      }
    },
    onImageLoad(index){
      var team = this.arr_team[index]
      team.is_image_loaded = true
      this.$set(this.arr_team, index, team)
    },
    manage_start_animation(){
      var context = this
      this.flag.aboutUsTitle1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsTitle1Flag, this.arr_factor, 0)
      this.flag.aboutUsImage1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsImage1Flag, this.arr_factor, 0)
      this.flag.aboutUsContent1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsContent1Flag, this.arr_factor, 0)

      setTimeout(() => {
        if(context.flag.aboutUsContent1Flag){
          var margin = window.$('#about-us-content').height()
          context.flag.aboutUsTitle2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle2Flag, context.arr_factor, 0, margin - margin)
          context.flag.aboutUsContent2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent2Flag, context.arr_factor, 0, margin - 300)
          
          if(context.flag.aboutUsContent2Flag){
            context.flag.aboutUsImage2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage2Flag, context.arr_factor, 0, margin + window.$('#trust-content').innerHeight() - 300)

            if(context.flag.aboutUsImage2Flag){
              margin += window.$('#trust-container').innerHeight() - 950
              context.flag.aboutUsTitle3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsImage3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsContent3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent3Flag, context.arr_factor, 800, margin)
            }
          }
        }
      }, 200)
      
    },
    handleScroll(){
      this.scrollY = window.scrollY
    },
  }
}
</script>

<style lang="scss">
.custom-title{
  color: $primary;
  font-family: poppins-bold;
}
.sponsor-detail{
  font-family: montserrat-light;
  color: $gray20;
}
.sponsor-detail1{
  font-family: montserrat-light;
  color: $primary;
}
.sponsor-why-title{
  // font-family: montserrat-light;
  color: $primary;
  font-size: 2rem;
}
.sponsor-why-detail{
  // font-family: montserrat-light;
  color: $primary;
}
.sponsor-reason-title{
  font-family: montserrat-bold;
  color: $primary;
}
.sponsor-reason-detail{
  font-family: montserrat-light;
  color: $gray20;
}
.about-us-medal-detail{
  color: $gray20;
}
.bg-gray22{
  background-color: $gray22;
}
.trust-card{
  background-color: $gray9;
  border: none;
}
.title-section{
  font-size: 2rem;
  font-family: poppins-medium;
}
.content-section{
  color: $gray6;
}
.team-role{
  color: $black1;
}
.team-name{
  color: $black1;
  font-family: poppins-bold;
}
.about-us-title1-enter-active, .about-us-title1-leave-active,
.about-us-title2-enter-active, .about-us-title2-leave-active,
.about-us-title3-enter-active, .about-us-title3-leave-active{
  transition: all 2s;
}
.about-us-title1-leave-to, .about-us-title1-enter,
.about-us-title2-leave-to, .about-us-title2-enter,
.about-us-title3-leave-to, .about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}
.about-us-content1-enter-active, .about-us-content1-leave-active,
.about-us-content2-enter-active, .about-us-content2-leave-active,
.about-us-content3-enter-active, .about-us-content3-leave-active{
  transition: all 2s;
}
.about-us-content1-leave-to, .about-us-content1-enter,
.about-us-content2-leave-to, .about-us-content2-enter,
.about-us-content3-leave-to, .about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}
.about-us-image1-enter-active, .about-us-image1-leave-active,
.about-us-image2-enter-active, .about-us-image2-leave-active,
.about-us-image3-enter-active, .about-us-image3-leave-active{
  transition: all 2s;
}
.about-us-image1-leave-to, .about-us-image1-enter,
.about-us-image2-leave-to, .about-us-image2-enter,
.about-us-image3-leave-to, .about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}
#about-us-content img{
  width: 100%;
  border-radius: 1rem;
}
.trust-list > p:nth-child(1){
  min-height: 100px;
  text-align: center;
}
.trust-list > p:nth-child(2){
  text-align: justify;
  text-align-last: center;
}
</style>

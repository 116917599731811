<template>
  <div class="position-relative">
    <div class="container-md">
      <div class="row bg-primary">
        <div class="col-auto d-flex flex-column justify-content-center">
          <p class="m-0 text-warning supporting-title">{{ $t("student_open") }}</p>
          <p class="m-0 text-uppercase text-white navbar-detail-subtitle">{{ title }}</p>
        </div>

        <div class="col p-0 d-flex justify-content-end" style="position: unset;">
          <img class="d-block d-md-none" src="@/assets/navbar_detail.png" width="100%" style="max-width: 481px;">
          <img class="d-none d-md-block" src="@/assets/navbar_detail.png" width="100%"
            style="max-width: 481px; opacity: 0">
          <img class="d-none d-md-block" src="@/assets/navbar_detail.png" width="100%"
            style="max-width: 481px; position: absolute; right: 0">
        </div>
        <!-- <div class="d-flex justify-content-between align-items-center">
        </div> -->

      </div>
    </div>
    <div class="navbar-detail-card2">
      <!-- <div class="container d-flex justify-content-center py-4">
        <div v-for="(main_sponsor, index) in arr_main_sponsor" :key="index" class="bg-white mr-3 rounded">
          <img :src="main_sponsor.image" style="height: 5rem;">
        </div>
      </div> -->

      <div class="container" v-if="arr_main_sponsor.length > 0">
        <VueSlickCarousel v-bind="settings">
          <div v-for="(main_sponsor, index) in arr_main_sponsor" :key="index"
            class="mr-3 rounded d-flex justify-content-center">
            <img :src="main_sponsor.image" class="carousel-sponsor-image">

          </div>
        </VueSlickCarousel>
      </div>

    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// import moment from 'moment';

import NoImage from '@/assets/no_image.png';
// import Football from '@/assets/football.png';
// import Futsal from '@/assets/futsal.png';
// import Basket from '@/assets/basket.png';
// import Softball from '@/assets/softball.png';
// import Badminton from '@/assets/badminton.png';
// import Tennis from '@/assets/tennis.png';
// import Taekwondo from '@/assets/taekwondo.png';

export default {
  components: {
    VueSlickCarousel
  },
  props: ['title',],
  data() {
    return {
      base: null,
      total_player: 6312,
      total_coach: 1488,
      total_school: 150,
      total_sport_club: 243,
      arr_main_sponsor: [
        // {
        //   id: "1",
        //   image: NoImage,
        // },
        // {
        //   id: "1",
        //   image: NoImage,
        // },
        // {
        //   id: "1",
        //   image: NoImage,
        // },
        // {
        //   id: "1",
        //   image: NoImage,
        // },
        // {
        //   id: "1",
        //   image: NoImage,
        // },
        // {
        //   id: "1",
        //   image: NoImage,
        // },
        // {
        //   id: "1",
        //   image: NoImage,
        // },
        // {
        //   id: "1",
        //   image: NoImage,
        // },
        // {
        //   id: "1",
        //   image: NoImage,
        // },
        // {
        //   id: "1",
        //   image: NoImage,
        // },
      ],
      settings: {
        "dots": false,
        "arrows": false,
        "focusOnSelect": true,
        "infinite": true,
        "speed": 500,
        // "slidesToShow": 5,
        // "slidesToScroll": 1,
        // "centerMode": true,
        // "centerPadding": "10px",
        "touchThreshold": 5,
        "autoplay": true,
        "centerMode": true,
        "centerPadding": "20px",
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "variableWidth": true,
        // "speed": 2000,
        "autoplaySpeed": 2000,
        // "responsive": [
        //   {
        //     "breakpoint": 992,
        //     "settings": {
        //       "slidesToShow": 4
        //     }
        //   },
        //   {
        //     "breakpoint": 768,
        //     "settings": {
        //       "slidesToShow": 3
        //     }
        //   }
        // ]
        // "cssEase": "linear"
        // "variableWidth": true
      }
    }
  },
  watch: {
    isLoading(val) {
      this.$emit("onLoading", val, 2)
    },
  },
  created() {
    this.base = new Base()

    this.get_main_sponsor()
    this.get_official_partner()
  },
  methods: {
    async get_main_sponsor() {
      var response = await this.base.request(this.base.url_api + "/main-sponsor/all")
      this.isLoading = false

      if (response != null) {
        if (response.status === "success") {
          var arr_main_sponsor = this.arr_main_sponsor
          for (let main_sponsor of response.data) {
            main_sponsor.image = this.base.host + "/media/main-sponsor?file_name=" + main_sponsor.file_name
            arr_main_sponsor.push(main_sponsor)
          }
          this.arr_main_sponsor = arr_main_sponsor
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_official_partner() {
      var response = await this.base.request(this.base.url_api + "/official-partner/all")
      this.isLoading = false

      if (response != null) {
        if (response.status === "success") {
          var arr_main_sponsor = this.arr_main_sponsor
          for (let official_partner of response.data) {
            official_partner.image = this.base.host + "/media/official-partner?file_name=" + official_partner.file_name
            arr_main_sponsor.push(official_partner)
          }
          this.arr_main_sponsor = arr_main_sponsor
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
  .article-first {
    padding-right: .5rem;
  }

  .article-last {
    padding-right: .5rem;
  }

  .article-second {
    padding-left: .5rem;
  }
}

@media only screen and (min-width: 720px) {
  .article-first {
    padding-right: .5rem;
  }

  .article-last {
    padding-left: .5rem;
  }

  .article-second {
    padding: 0 .5rem;
  }
}

.supporting-title {
  font-family: 'montserrat-bold';
  font-size: 2rem;
}

.navbar-detail-subtitle {
  font-family: 'montserrat-medium';
}

.navbar-detail-card2 {
  background-color: $blue5;
  width: 100%;
}

.home-article-title1-enter-active,
.home-article-title1-leave-active {
  transition: all 2s;
}

.home-article-title1-leave-to,
.home-article-title1-enter {
  transform: translateY(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}

.home-article-title2-enter-active,
.home-article-title2-leave-active {
  transition: all 2s;
}

.home-article-title2-leave-to,
.home-article-title2-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.home-article-content-enter-active,
.home-article-content-leave-active {
  transition: all 2s;
}

.home-article-content-leave-to,
.home-article-content-enter {
  transform: translateX(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}

.carousel-sponsor-image {
  max-height: 5rem;
  background-color: white;
  border-radius: 0.5rem;
}
</style>

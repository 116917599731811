<template>
  <div>
    <div class="item" v-if="group.arr[group.arr.length - 2] != null">
      <div  :class="{'item-parent': group.arr[group.arr.length - 2] != null && arr_match_tournament.length > 0}">
				<div class="position-relative">
					<TournamentMatch :tournament="group.arr[group.arr.length - 1].arr[0]" @onMatchClicked="(match) => $emit('onMatchClicked', match)" @onMatchEditClicked="(match) => $emit('onMatchEditClicked', match)" :user="user"/>

					<div v-if="group.arr[group.arr.length - 1].arr.length > 1" class="position-absolute d-flex align-items-center"
						:style="{
							bottom: '-40rem',
						}">
						<TournamentMatch :tournament="group.arr[group.arr.length - 1].arr[1]" @onMatchClicked="(match) => $emit('onMatchClicked', match)" @onMatchEditClicked="(match) => $emit('onMatchEditClicked', match)" :user="user"/>
					</div>
				</div>


      </div>
      <TournamentChildren :arr_prev="arr_match_tournament" :arr="group.arr" :user="user" :group_index="group.arr.length - 2" v-if="arr_match_tournament.length > 0" @onMatchClicked="(match) => $emit('onMatchClicked', match)" @onMatchEditClicked="(match) => $emit('onMatchEditClicked', match)"/>
    </div>
		<div v-else>
			<TournamentMatch :tournament="group.arr[group.arr.length - 1].arr[0]" @onMatchClicked="(match) => $emit('onMatchClicked', match)" @onMatchEditClicked="(match) => $emit('onMatchEditClicked', match)" :user="user"/>
		</div>
  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";
import TournamentChildren from "@/pages/competition/component/tournament_children.vue";
import TournamentMatch from "@/pages/competition/component/tournament_match.vue";

export default {
  props: ["group", 'user',],
  components: {
    TournamentChildren: TournamentChildren,
    TournamentMatch: TournamentMatch,
  },
  data() {
    return {
      base: null,
      arr_match_tournament: [],
    };
  },
  watch: {
    group(val){

    },
  },
  async mounted() {
    this.base = new Base();

    var arr_match_tournament = []
		if(this.group.arr[this.group.arr.length - 2] != null){
			for(let match_prev_round of this.group.arr[this.group.arr.length - 2].arr){
				if(this.group.arr[this.group.arr.length - 1].arr[0].from_tournament1.id == match_prev_round.id)
					arr_match_tournament.push(match_prev_round)
				else if(this.group.arr[this.group.arr.length - 1].arr[0].from_tournament2.id == match_prev_round.id)
					arr_match_tournament.push(match_prev_round)
			}
		}

    this.arr_match_tournament = arr_match_tournament
  },
  methods: {},
};
</script>

<style lang="scss">
.matchEventGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
</style>

<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail
        :title="competition_data.category_sport != null && competition_data.category_sport.name != null ? competition_data.category_sport.name : ''" />
    </div>
    <CompetitionTab :competition_data="competition_data" />

    <div class="my-5 pb-5 container" v-show="!isDetailFlag">
      <div class="py-3">
				<p class="m-0 competition-detail-title">{{ $t(competition_data.id != null && competition_data.scoring_type.data == 'manual' ? 'manual' : "cutoff") }}</p>

        <div class="py-3">
					<div class="d-flex">

						<div class="form-group">
							<select
								class="form-control"
								style="font-size: 0.75rem; height: 2.5rem; width: auto; background-position: right 0.75rem center"
								v-model="type">
								<option value="">All</option>
								<option value="club">Sport Club</option>
								<option value="school">Academy</option>
							</select>
						</div>

						<div class="form-group ml-3">
							<select
								class="form-control"
								style="font-size: 0.75rem; height: 2.5rem; width: auto; background-position: right 0.75rem center"
								v-model="category">
								<option value="">All</option>
								<option
									:value="event_category_sport_category.id"
									v-for="(event_category_sport_category, index) in arr_event_category_sport_category"
									:key="index">
									{{ event_category_sport_category.name }}
								</option>
							</select>
						</div>

					</div>

          <div>
						<div v-if="arr_group.length > 0">
							<div v-for="(group, index) in arr_group" :key="index" class="border-bottom py-4">

								<div class="d-flex justify-content-between align-items-center">

									<p class="m-0 font-weight-bold">{{ group.type != null ? group.type.name + ' /' : '' }} {{ group.event_category_sport_category.name }}</p>

									<div class="d-flex align-items-center">
										<button class="btn btn-primary mr-3" v-if="user != null && user.type != null && user.type.name == 'coordinator'" @click="onEdit(index)">Edit</button>
										<button class="btn btn-primary mr-3" v-if="user != null && user.type != null && user.type.name == 'coordinator'" @click="onSelectedGroup(index)">Input {{ competition_data.id != null && competition_data.scoring_type.data == 'manual' ? 'Winner' : 'Score' }}</button>
										<p class="m-0 font-weight-bold">{{ group.arr[0].member.length }} / {{ group.cutoff_category.name }}</p>
									</div>

								</div>

								<table class="table ladder-table mt-3">
									<thead>
										<tr>
											<th style="width: 40%">School / Team</th>
											<th class="text-center" v-for="(cutoff_category, index1) in group.arr_cutoff_category" :key="index1" style="vertical-align: top">
												<div class="d-flex justify-content-center align-items-center">
													<div class="text-left">
														<p class="m-0">{{ group.event_category_sport_category.event_category_sport.cutoff_match_type == 'multiple' ? 'Round ' + cutoff_category.name : 'Single' }}</p>

														<div v-if="cutoff_category.match != null" style="font-size: .8rem">
															<p class="m-0">{{ cutoff_category.match.event_category_sport_venue != null ? cutoff_category.match.event_category_sport_venue.venue.name : 'No Venue' }}</p>
														</div>
														<p class="m-0" v-else>N/A</p>
													</div>

													<div class="ml-3 text-right">

														<div v-if="cutoff_category.match != null && cutoff_category.match.date != null" style="font-size: .8rem">
															<p class="m-0">{{ cutoff_category.match.date.format('DD MMM YYYY') }}</p>
															<p class="m-0">{{ cutoff_category.match.date.format('HH:mm') }}</p>
														</div>
														<p class="m-0" v-else>N/A</p>
													</div>

													<div class="ml-3">
														<font-awesome-icon :icon="['fas', 'sort']" class="cutoff-sort"
															:class="{'active': sort_cutoff_category == cutoff_category.id || (sort_cutoff_category == '' && index1 == group.arr_cutoff_category.length - 1),}" @click="onSortClicked(index, index1)"
															style="cursor: pointer;"/>
													</div>
												</div>
											</th>
										</tr>
									</thead>

									<tbody>
										<tr v-for="(member, index1) in group.arr[0].member" :key="index1">

											<td>
												<div class="d-flex align-items-center h-100">

													<div class="d-flex align-items-center justify-content-center" style="height: 5rem; width: 5rem;">
														<img :src="member.registration_event.url_image" style="max-height: 100%; width: auto; max-width: 100%;" />
													</div>

													<div class="ml-3">
														<p class="m-0 font-weight-bold">{{ member.registration_event.team_name }}</p>
														<p class="m-0">{{ member.registration_event.user.name }}</p>
													</div>

												</div>
											</td>

											<td class="text-center" v-for="(cutoff_category, index2) in member.arr_cutoff_category" :key="index2">
												<div v-if="cutoff_category.is_qualified">

													<div v-if="cutoff_category.match_event != null" class="d-flex justify-content-center align-items-center">

														<div v-if="group.event_category_sport_category.event_category_sport.scoring_type.data != 'manual'">
															<div class="py-3 text-center" v-if="group.event_category_sport_category.event_category_sport.cutoff_scoring_type == 'timer'">
																{{
																	(cutoff_category.match_event.timer_moment.hours() < 10 ? '0' + cutoff_category.match_event.timer_moment.hours() : cutoff_category.match_event.timer_moment.hours()) + ':' +
																	(cutoff_category.match_event.timer_moment.minutes() < 10 ? '0' + cutoff_category.match_event.timer_moment.minutes() : cutoff_category.match_event.timer_moment.minutes()) + ':' +
																	(cutoff_category.match_event.timer_moment.seconds() < 10 ? '0' + (cutoff_category.match_event.timer_moment.seconds()) : cutoff_category.match_event.timer_moment.seconds()) + '.' +
																	(cutoff_category.match_event.timer_moment.milliseconds() < 10 ? '00' + (cutoff_category.match_event.timer_moment.milliseconds()) : cutoff_category.match_event.timer_moment.milliseconds() < 100 ? '0' + cutoff_category.match_event.timer_moment.milliseconds() : cutoff_category.match_event.timer_moment.milliseconds())
																}}
															</div>

															<div class="py-3 text-center" v-else>
																{{ cutoff_category.match_event.total_score.toLocaleString(base.locale_string) }}
															</div>
														</div>

														<div class="ml-1">({{ cutoff_category.match_event.rank }})</div>
													</div>

													<div v-else-if="group.event_category_sport_category.event_category_sport.scoring_type.data == 'manual'" class="text-center">Participant</div>

													<div v-else class="text-center">-</div>

												</div>

												<div v-else>
													<font-awesome-icon :icon="['fas', 'xmark']" class="text-danger" v-if="group.match.cutoff_category.level >= cutoff_category.level"/>

													<p class="m-0 text-center" v-else>-</p>
												</div>
											</td>

										</tr>
									</tbody>
								</table>

							</div>
						</div>

						<div v-else>
							<h4 class="text-center">No Cutoff Found</h4>
						</div>

          </div>
        </div>
      </div>
    </div>

    <CutoffDetail :group="selected_group" :user_data="user" v-show="isDetailFlag" @onBackClicked="onBackDetailMatch"
      @onForceReload="onForceReload" />
    <ModalMatch v-if="user != null && user.type != null && user.type.name == 'coordinator'" :match="selected_match" :data="competition_data" :onShow="is_show_modal" @onForceReload="onForceReload"/>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'
import CutoffDetail from '@/pages/competition/cutoff_detail.vue'
import ModalMatch from "@/pages/competition/modal_match";

import NoImage from '@/assets/no_image.png'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
    'CompetitionTab': CompetitionTab,
    'CutoffDetail': CutoffDetail,
    'ModalMatch': ModalMatch,
    // DatePicker,
  },
  data() {
    return {
      base: null,
      arr_factor: [false,],
      type: "",
			category: "",
			arr_event_category_sport_category: [],
      arr_group: [],
      competition_data: {},
      selected_group: {},
			selected_match: {},
      selected_index: -1,
      isDetailFlag: false,
      is_show_modal: false,
      user: {},
			sort_cutoff_category: '',
    }
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    date() {
      this.get_data()
    },
		type(){
			// window.$('#please_wait_modal').modal('show')
			this.get_data()
		},
		category(){
			// window.$('#please_wait_modal').modal('show')
			this.get_data()
		},
		sort_cutoff_category(){
			this.get_data()
		},
  },
  async mounted() {
    this.base = new Base()
    // window.addEventListener('scroll', this.handleScroll)
    // this.arr_factor = [true,]
    var user = await window.localStorage.getItem('user')
    this.user = JSON.parse(user)

		this.get_event_category_sport_category_data()
    this.get_competition_data()
    this.get_data()
  },
  methods: {
		onSortClicked(index, index1){
			this.sort_cutoff_category = this.arr_group[index].arr_cutoff_category[index1].id

		},
    onEdit(index){
      this.selected_match = this.arr_group[index].match
      this.selected_index = index
      this.is_show_modal = true
      window.$('#editMatch').modal('show')
    },
    onSelectedGroup(index) {
      // if(this.user != null && this.user.id != null){
        this.selected_group = this.arr_group[index]
        this.selected_index = index
        this.isDetailFlag = true
      // }
    },
    onBackDetailMatch(isFinished) {
      this.isDetailFlag = false

			// if(isFinished)
				this.get_data()
			// else
			// 	this.get_one_data()
    },
    onForceReload() {
      this.get_data()
    },
    disabledDate(date) {
      return this.competition_data.id != null && (momentTZ(date).isBefore(this.competition_data.start_date_moment) || momentTZ(date).isAfter(this.competition_data.end_date_moment))
    },
		async get_event_category_sport_category_data() {
			var response = await this.base.request(
				this.base.url_api + "/event/category-sport/category/all?event_category_sport_id=" + this.$route.query.id
			);
			this.$set(this.arr_factor, 1, true);

			if (response != null) {
				if (response.status === "success") {
					this.arr_event_category_sport_category = response.data;
				} else this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
    async get_competition_data() {
      var response = await this.base.request(this.base.url_api + "/event/category-sport?id=" + this.$route.query.id)

      if (response != null) {
        if (response.status === "success") {
          response.data.start_date_moment = momentTZ.tz(response.data.start_date, this.base.locale_timezone)
          response.data.end_date_moment = momentTZ.tz(response.data.end_date, this.base.locale_timezone)
          response.data.event.start_school_registration_moment = momentTZ.tz(response.data.event.start_school_registration, this.base.locale_timezone)
          response.data.event.end_school_registration_moment = momentTZ.tz(response.data.event.end_school_registration, this.base.locale_timezone)
          response.data.event.start_registration_team_moment = momentTZ.tz(response.data.event.start_registration_team, this.base.locale_timezone)
          response.data.event.end_registration_team_moment = momentTZ.tz(response.data.event.end_registration_team, this.base.locale_timezone)
          response.data.event.start_payment_registration_moment = momentTZ.tz(response.data.event.start_payment_registration, this.base.locale_timezone)
          response.data.event.end_payment_registration_moment = momentTZ.tz(response.data.event.end_payment_registration, this.base.locale_timezone)
          response.data.event.coach_meeting_moment = momentTZ.tz(response.data.event.coach_meeting, this.base.locale_timezone)
          response.data.event.medal_ceremony_moment = momentTZ.tz(response.data.event.medal_ceremony, this.base.locale_timezone)

          this.competition_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_one_data() {
      var response = await this.base.request(this.base.url_api + '/match/all?id=' + this.arr_match[this.selected_index].id + '&rel_type=simple')

      if (response != null) {
        if (response.status == "success") {
            response.data.date = momentTZ.tz(response.data.date, this.base.locale_timezone)

						if(response.data.cutoff_group == null){
							response.data.member1.registration_event.url_image = response.data.member1.registration_event.url_image != null ? response.data.member1.registration_event.url_image : NoImage
							response.data.member2.registration_event.url_image = response.data.member2.registration_event.url_image != null ? response.data.member2.registration_event.url_image : NoImage
						}
          this.$set(this.arr_match, this.selected_index, response.data)
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_data() {
      var counter = 0
      for(let factor of this.arr_factor){
        if(!factor)
          break
        counter++
      }

      if(counter == this.arr_factor.length)
        window.$('#please_wait_modal').modal('show')

      var date = this.date != '' && this.date != null ? momentTZ(this.date, 'DD/MM/YYYY') : ''
			var token = await window.localStorage.getItem('token')
      var response = await this.base.request(this.base.url_api + '/cutoff-group/one-group' + (token != null ? '/auth' : '') + '?' +
				'api_type=one_group' +
				'&event_category_sport_category_id=' + this.category +
				'&type=' + this.type +
				'&event_category_sport_id=' + this.$route.query.id +
				'&sort_cutoff_category=' + this.sort_cutoff_category)

      this.$set(this.arr_factor, 0, true)
      if(counter == this.arr_factor.length)
				setTimeout(() => {
					window.$('#please_wait_modal').modal('hide')
				}, 500)

      if (response != null) {
        if (response.status == "success") {
					for(let data of response.data){
						data.match.date = momentTZ.tz(data.match.date, this.base.locale_timezone)

						for(let member of data.arr[0].member){
							for(let cutoff_category of member.arr_cutoff_category){

								if(data.event_category_sport_category.event_category_sport.cutoff_scoring_type == 'timer' && cutoff_category.match_event != null){
									var temp = cutoff_category.match_event.total_score.toLocaleString().split('.')[1]
									cutoff_category.match_event.timer_moment = momentTZ.duration({
										seconds: parseInt(cutoff_category.match_event.total_score),
										milliseconds: temp != null ? (temp.length == 1 ? temp + '00' : temp.length == 2 ? temp + '0' : temp) : '0',
									})
								}
							}
						}

						for(let cutoff_category of data.arr_cutoff_category){
							if(cutoff_category.match != null)
								cutoff_category.match.date = momentTZ.tz(cutoff_category.match.date, this.base.locale_timezone)
						}
					}

          this.arr_group = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/app.scss";

@include media-breakpoint-up(md) {
  .text-md-normal {
    font-size: 1rem;
  }
  .score-container{
    height: 5rem;
  }

  .member-container {
    p {
      font-size: 1rem;
    }

    .match-image-container {
      height: 5rem;

      img {
        width: 5rem;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .text-small {
    font-size: 0.875rem;
  }
  .score-container{
    height: 4rem;
  }

  .member-container {
    p {
      font-size: 0.875rem;
    }

    .match-image-container {
      height: 4rem;

      img {
        width: 4rem;
      }
    }
  }
}

.cutoff-sort{
	color: $black;
}
.cutoff-sort.active{
	color: $warning;
}
.cutoff-title{
	font-family: "montserrat-bold";
	font-size: 2rem;
	color: $primary1;
}


.match-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto 1fr;
  gap: 1rem;
}

.competition-detail-venue-court {
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

.cursor-pointer {
  cursor: pointer;
}
.selector-hover:hover{
  background-color: #eaeaea;
}

// .form-control{
//   background-position: right .75rem center;
// }</style>

<template>
  <div class="px-5 py-5 w-100 bg-warning">
    <div class="container">
      <div>
        <p class="m-0 text-primary text-center sponsor-title1">{{ $t("main_sponsor_title") }}</p>

        <!-- <div class="row my-3">
          <div v-for="(main_sponsor, index) in arr_main_sponsor" :key="index" class="col-4 col-md-4 col-lg-2 mb-3">
            <img :src="main_sponsor.image" class="w-100">
          </div>
        </div> -->

        <div class="main-sponsor-flex mt-3">
          <img v-for="(main_sponsor, index) in arr_main_sponsor" :key="index" :src="main_sponsor.image" class="main-sponsor-flex-item">
        </div>
      </div>

      <div class="sponsor-hr my-5"></div>

      <div>
        <p class="m-0 text-primary text-center sponsor-title2">{{ $t("official_partner_title") }}</p>

        <!-- <div class="row my-3">
          <div v-for="(official_partner, index) in arr_official_partner" :key="index"
            class="col-3 col-md-4 col-lg-2 mb-3">
            <img :src="official_partner.image" class="w-100">
          </div>
        </div> -->

        <div class="official-partner-flex mt-3 mb-5">
          <img v-for="(official_partner, index) in arr_official_partner" :key="index" :src="official_partner.image" class="official-partner-flex-item">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
// import moment from 'moment';


import NoImage from '@/assets/no_image.png';

export default {
  components: {
  },
  props: ['articleTitle1Flag', 'articleTitle2Flag',],
  data() {
    return {
      base: null,
      arr_main_sponsor: [
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
      ],
      arr_official_partner: [
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
        {
          id: "1",
          image: NoImage,
        },
      ],
    }
  },
  watch: {
    isLoading(val) {
      this.$emit("onLoading", val, 2)
    },
  },
  created() {
    this.base = new Base()

    this.get_main_sponsor()
    this.get_official_partner()
  },
  methods: {
    async get_main_sponsor() {
      var response = await this.base.request(this.base.url_api + "/main-sponsor/all")
      this.$emit('onCompleteRequest', 2)

      if (response != null) {
        if (response.status === "success") {
          for (let main_sponsor of response.data) {
            main_sponsor.image = this.base.host + "/media/main-sponsor?file_name=" + main_sponsor.file_name
          }
          this.arr_main_sponsor = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_official_partner() {
      var response = await this.base.request(this.base.url_api + "/official-partner/all")
      this.$emit('onCompleteRequest', 3)

      if (response != null) {
        if (response.status === "success") {
          for (let official_partner of response.data) {
            official_partner.image = this.base.host + "/media/official-partner?file_name=" + official_partner.file_name
          }
          this.arr_official_partner = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 768px) {
  .article-first {
    padding-right: .5rem;
  }

  .article-last {
    padding-right: .5rem;
  }

  .article-second {
    padding-left: .5rem;
  }
  
  .main-sponsor-flex-item{
    width: calc((100% / 3) - (1rem / 1.45));
  }

  .official-partner-flex-item{
    width: calc((100% / 4) - (1rem / 1.45));
  }
}

@media only screen and (min-width: 768px) {
  .article-first {
    padding-right: .5rem;
  }

  .article-last {
    padding-left: .5rem;
  }

  .article-second {
    padding: 0 .5rem;
  }

  .main-sponsor-flex-item{
    width: calc((100% / 7) - (1rem / 1.15));
  }

  .official-partner-flex-item{
    width: calc((100% / 8) - (1rem / 1.14));
  }
}

.main-sponsor-flex{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem
}

.official-partner-flex{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem
}

.supporting-title {
  font-family: 'montserrat-bold';
  font-size: 2rem;
}

.check-certificate-detail {
  font-family: 'montserrat-regular';
}

.sponsor-title1 {
  font-family: 'montserrat-bold';
  font-size: 2rem;
}

.sponsor-title2 {
  font-family: 'montserrat-bold';
  font-size: 1.5rem;
}

.sponsor-hr {
  width: 100%;
  height: 1px;
  border: 1px solid $gray21;
}

.our-activity-content {
  font-family: 'montserrat-regular';
  color: $gray20;
}

.home-article-title1-enter-active,
.home-article-title1-leave-active {
  transition: all 2s;
}

.home-article-title1-leave-to,
.home-article-title1-enter {
  transform: translateY(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}

.home-article-title2-enter-active,
.home-article-title2-leave-active {
  transition: all 2s;
}

.home-article-title2-leave-to,
.home-article-title2-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.home-article-content-enter-active,
.home-article-content-leave-active {
  transition: all 2s;
}

.home-article-content-leave-to,
.home-article-content-enter {
  transform: translateX(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
</style>

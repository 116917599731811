<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail :title="competition_data.category_sport != null && competition_data.category_sport.name != null ? competition_data.category_sport.name : ''" />
    </div>
    <CompetitionTab :competition_data="competition_data"/>

    <div class="my-5 pb-5 container">
      <div class="py-3">
        <p class="m-0 competition-detail-title">{{ $t('ladder') }}</p>

        <div class="py-3">
          <div class="form-group ">
            <select class="form-control"
              style="font-size: 0.75rem; height: 2.5rem; width: auto; background-position: right .75rem center;" v-model="type">
              <option value="">All</option>
              <option value="club">Sport Club</option>
              <option value="school">Academy</option>
            </select>
          </div>

          <div>
            <div v-for="(group, index) in arr_group" :key="index" class="mb-5 overflow-auto">
              <h4 class="position-sticky mb-4" style="left: 0;" v-if="competition_data.scoring_type.data != 'cutoff' && competition_data.scoring_type.data != 'cutoff_tournament'">{{ group.member[0].user.type.name }} / {{ group.event_category_sport_category.name }} / Group {{ group.name }}</h4>
							<h4 class="position-sticky mb-4" style="left: 0;" v-else>{{ group.cutoff_category.name }}</h4>
							
              <table class="table ladder-table">
                <thead>
                  <tr>
                    <th style="width: 40%">School / Team</th>
                    <th class="text-center">P</th>
                    <th class="text-center">W</th>
                    <th class="text-center">D</th>
                    <th class="text-center">L</th>
                    <th class="text-center">SF</th>
                    <th class="text-center">SA</th>
                    <th class="text-center">SD</th>
                    <th class="text-center">Pts</th>
                    <th class="text-center" :style="{'width': (max_form_length * 2.25) + 'rem'}">Form</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(member, index1) in group.member" :key="index1">
                    <td>
                      <div class="d-flex align-items-center h-100">
                        <div class="d-flex align-items-center justify-content-center" style="height: 5rem;">
                          <img :src="member.url_image" style="width: 5rem" />
                        </div>
                        <div class="ml-3">
                          <p class="m-0 font-weight-bold">{{ member.registration_event.team_name }}</p>
                          <p class="m-0">{{ member.user.name }}</p>
                        </div>
                      </div>
                    </td>
                    <td class="text-center">{{ member.played }}</td>
                    <td class="text-center">{{ member.won }}</td>
                    <td class="text-center">{{ member.draw }}</td>
                    <td class="text-center">{{ member.lost }}</td>
                    <td class="text-center">{{ member.goal_for }}</td>
                    <td class="text-center">{{ member.goal_away }}</td>
                    <td class="text-center">{{ member.goal_difference }}</td>
                    <td class="text-center">{{ member.point }}</td>
                    <td class="text-center">
                      <div class="d-flex">
                        <div v-for="(form, index) in member.arr_form" :key="index">
                          <!-- <p class="m-0">M{{ index + 1 }}</p> -->
                          <div class="rounded-circle d-flex align-items-center justify-content-center mr-1" 
                            :class="{
                              'bg-success text-white': form.status_match == 'win', 
                              'bg-danger text-white': form.status_match == 'lose', 
                              'bg-warning text-white': form.status_match == 'draw', 
                              'bg-e6 text-white': form.status_match == 'not_started',
                            }" 
                            style="aspect-ratio: 1; width: 2rem;">
                            <p class="m-0" v-show="form.status_match != 'not_started'">{{ form.status_match == 'win' ? 'W' : (form.status_match == 'lose' ? 'L' : 'D') }}</p>
                            <p class="m-0" v-show="form.status_match == 'not_started'">?</p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              
            </div>
          </div>
					
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'

import NoImage from '@/assets/no_image.png'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
    'CompetitionTab': CompetitionTab,
  },
  data() {
    return {
      base: null,
      arr_factor: [false, ],
      type: '',
      arr_group: {},
      competition_data: {},
      max_form_length: 0,
    }
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    type(){
      this.get_data()
    },
    arr_group(val){
      var max_form_length = 0
			for(let group of val){
				for(let member of group.member){
					if(max_form_length < member.arr_form.length)
						max_form_length = member.arr_form.length
				}
			}
      this.max_form_length = max_form_length
    },
		competition_data(val){
			if(val.id != null){
				if(val.scoring_type.data != 'cutoff' && val.scoring_type.data != 'cutoff_tournament')
					this.get_data()
				else
					this.get_cutoff_data()
			}
		},
  },
  mounted() {
    this.base = new Base()
    // window.addEventListener('scroll', this.handleScroll)
    // this.arr_factor = [true,]

    this.get_competition_data()
    
  },
  methods: {
    async get_competition_data(){
      var response = await this.base.request(this.base.url_api + "/event/category-sport?id=" + this.$route.query.id)

      if(response != null){
        if(response.status === "success"){
          response.data.start_date_moment = momentTZ.tz(response.data.start_date, this.base.locale_timezone)
          response.data.end_date_moment = momentTZ.tz(response.data.end_date, this.base.locale_timezone)
          response.data.event.start_school_registration_moment = momentTZ.tz(response.data.event.start_school_registration, this.base.locale_timezone)
          response.data.event.end_school_registration_moment = momentTZ.tz(response.data.event.end_school_registration, this.base.locale_timezone)
          response.data.event.start_registration_team_moment = momentTZ.tz(response.data.event.start_registration_team, this.base.locale_timezone)
          response.data.event.end_registration_team_moment = momentTZ.tz(response.data.event.end_registration_team, this.base.locale_timezone)
          response.data.event.start_payment_registration_moment = momentTZ.tz(response.data.event.start_payment_registration, this.base.locale_timezone)
          response.data.event.end_payment_registration_moment = momentTZ.tz(response.data.event.end_payment_registration, this.base.locale_timezone)
          response.data.event.coach_meeting_moment = momentTZ.tz(response.data.event.coach_meeting, this.base.locale_timezone)
          response.data.event.medal_ceremony_moment = momentTZ.tz(response.data.event.medal_ceremony, this.base.locale_timezone)

          this.competition_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_data() {
      var response = await this.base.request(this.base.url_api + '/group/all?type=' + this.type + '&event_category_sport_id=' + this.$route.query.id);
      this.$set(this.arr_factor, 0, true)

      if (response != null) {
        if (response.status == "success") {
          for(let group of response.data){
            for(let member of group.member){
              member.date = momentTZ.tz(member.match_date, this.base.locale_timezone)
              member.url_image = member.registration_event.url_image != null ? member.registration_event.url_image : NoImage
              for(let form of member.arr_form){
                if(form.status == 'finished'){
                  if(form.group_member1_id == member.id)
                    form.status_match = form.group_member1_score > form.group_member2_score ? 'win' : (form.group_member1_score < form.group_member2_score ? 'lose' : 'draw')
                  else if(form.group_member2_id == member.id)
                    form.status_match = form.group_member1_score < form.group_member2_score ? 'win' : (form.group_member1_score > form.group_member2_score ? 'lose' : 'draw')
                }
                else
                  form.status_match = 'not_started'
              }
              for(let opponent of member.arr_opponent){
                opponent.date = momentTZ.tz(opponent.match_date, this.base.locale_timezone)
                opponent.url_image = opponent.user.file_name != null ? this.base.host + '/media/user?file_name=' + opponent.user.file_name : NoImage
              }
            }
          }
          this.arr_group = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
		async get_cutoff_data() {
			var response = await this.base.request(this.base.url_api + '/cutoff-member/all?event_category_sport_id=' + this.$route.query.id);
			this.$set(this.arr_factor, 0, true)
		
			if (response != null) {
				if (response.status == "success") {
					var arr_group = []
					for(let member of response.data){
						var index = -1
						for(let x in arr_group){
							if(arr_group[x].cutoff_category.id == member.cutoff_category.id){
								index = x
								break
							}
						}
						
						member.url_image = member.registration_event.url_image != null ? member.registration_event.url_image : NoImage
						for(let form of member.arr_form){
							if(form.status == 'finished'){
								if(form.group_member1_id == member.id)
									form.status_match = form.group_member1_score > form.group_member2_score ? 'win' : (form.group_member1_score < form.group_member2_score ? 'lose' : 'draw')
								else if(form.group_member2_id == member.id)
									form.status_match = form.group_member1_score < form.group_member2_score ? 'win' : (form.group_member1_score > form.group_member2_score ? 'lose' : 'draw')
							}
							else
								form.status_match = 'not_started'
						}
						
						if(index < 0)
							arr_group.push({
								cutoff_category: member.cutoff_category,
								member: [member],
							})
						else
							arr_group[index].member.push(member)
					}
					
					this.arr_group = arr_group
				}
				else
					this.base.show_error(response.message)
			}
			else
				console.log(this.$t('server_error'))
		},
  },
}
</script>

<style lang="scss">
.ladder-table td{
  vertical-align: middle;
}

.competition-detail-venue-court{
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

.bg-e6{
  background-color: #e6e6e6
}

// .form-control{
//   background-position: right .75rem center;
// }
</style>

<template>
  <div>
		<div class="row">
			<div class="col-3 mb-3" v-for="(registration, index) in arr_registration" :key="index">

				<div class="d-flex align-items-center justify-content-center justify-content-md-start flex-column flex-md-row member-container">
					<div class="d-flex align-items-center justify-content-center match-image-container">
						<img :src="registration.url_image"/>
					</div>
					<div class="text-center text-md-left mt-2 mt-md-0 ml-md-3">
						<p class="m-0 font-weight-bold">{{ registration.user.name }}</p>
						<p class="m-0">{{ registration.team_name }}</p>
						<p class="m-0 text-secondary">{{ registration.jersey_team }}</p>
					</div>
				</div>

				<div class="d-flex flex-column my-4" style="gap: 1.5rem">
					<div
						v-for="(player, index1) in registration.player"
						:key="index1">
						<div class="d-flex flex-row-reverse" style="gap: .5rem">

							<div class="d-flex align-items-center justify-content-center">
								<input
									class=""
									type="checkbox"
									:value="true"
									:checked="player.is_selected"
									:disabled="player.is_selected"
									v-show="match.status != 'finished'"
									@change="onAttendanceChanged(index, index1)"
									id="defaultCheck1"/>

								<div v-show="match.status == 'finished'">
									<p class="m-0" v-show="!player.is_selected">Not Attended</p>
									<p class="m-0" v-show="player.is_selected">Attended</p>
								</div>
							</div>

							<div class="d-flex flex-column flex-md-row flex-fill align-items-start align-items-md-center" style="gap: .5rem">
								<div
									class="d-flex align-items-center justify-content-center"
									style="width: 4rem; height: 4rem">
									<img :src="player.url_image" class="mw-100 mh-100" />
								</div>

								<div class="d-flex align-items-baseline flex-fill" style="gap: .5rem">
									<p class="m-0 text-center" style="min-width: 2rem;">{{ player.back_number }}</p>

									<div class="d-flex flex-column align-items-start">
										<p class="m-0 font-weight-bold text-primary">{{ player.name }}</p>

										<small class="m-0">
											{{
												player.player_position != null
													? player.player_position.name
													: "-"
											}}
										</small>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";

export default {
  props: ["match", 'user', 'rnd_time', ],
  components: {},
  data() {
    return {
      base: null,
			arr_registration: [],
      is_first_time: true,
    };
  },
  watch: {
    rnd_time(val){
      this.load_data()
    },
    match(val){
      this.load_data();
    },
  },
  async created() {
    this.base = new Base();

		var arr_registration = []
		for(let member of this.match.cutoff_group.member){
			for(let player of member.registration_event.player){
				player.is_selected = false
			}
			arr_registration.push(member.registration_event)
		}
		this.arr_registration = arr_registration
    this.load_data();
  },
  methods: {
    load_data(){
      if(this.match != null && this.match.id != null)
        this.get_attendance_data()
    },
    async get_attendance_data() {
      var response = await this.base.request(
        this.base.url_api + "/match/attendance/all?match_id=" + this.match.id
      );

      if (response != null) {
        if (response.status == "success") {
					var arr_registration = JSON.parse(JSON.stringify(this.arr_registration))
					for(let registration of arr_registration){
						for(let player of registration.player){
							player.is_selected = false
							for (let data of response.data) {
								if (data.registration_event_player.id == player.id) {
									player.is_selected = true
									break;
								}
							}

						}
					}
					this.arr_registration = arr_registration
          this.is_first_time = false

        } else this.base.show_error(response.message);
      } else console.log(this.$t("server_error"));
    },
		async onAttendanceChanged(index, index1) {

			var response = await this.base.request(
				this.base.url_api + "/match/attendance",
				"post",
				{
					match_id: this.match.id,
					registration_event_player: {
						id: this.arr_registration[index].player[index1].id,
					},
				}
			);

			if (response != null) {
				if (response.status == "success") {
					var arr_registration = JSON.parse(JSON.stringify(this.arr_registration));
					arr_registration[index].player[index1].is_selected = true
					this.arr_registration = arr_registration
				} else this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
  },
};
</script>

<style lang="scss">
.playerAttendanceGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
</style>

<script>

// import CKEditor from 'ckeditor4-vue'
import momentTZ from 'moment-timezone'
import $ from 'jquery'
import Base from "@/utils/base";
import NoImage from '@/assets/no_image.png'

import CustomEditTournament from "@/layout/CustomEditTournament";

const base = new Base()

/**
 * Event component
 */
export default {
	props: ['group_data', ],
	components: {CustomEditTournament, },
	data() {
		return {
			base: null,
			arr_group: [],
			event_category_sport_category_id: '',
			type_id: '',
		};
	},
	watch: {
		group_data(val){
			if(val.event_category_sport_category != null){
				this.event_category_sport_category_id = val.event_category_sport_category.id
				this.type_id = val.type != null ? val.type.id : ''
			}
		},
		event_category_sport_category_id(val){
			if(val != '')
				this.get_data()
		},
		type_id(val){
			if(val != '')
				this.get_data()
		},
	},
	created(){
		this.base = new Base()


	},
	methods: {
		async get_data() {
			this.arr_group = []
			var response = await this.base.request(
				this.base.url_api +
					"/tournament/group?type=" + this.type_id +
					"&event_category_sport_category_id=" + this.event_category_sport_category_id
			);

			if (response != null) {
				if (response.status == "success") {
					for (let group of response.data) {
						for (let level of group.arr) {
							for (let tournament of level.arr) {
								tournament.match = null

								tournament.date_moment = momentTZ.tz(tournament.date, this.base.locale_timezone);
								if(tournament.registration_event1 != null)
									tournament.registration_event1.url_image = tournament.registration_event1.url_image != null ? tournament.registration_event1.url_image : NoImage
								else{
									tournament.registration_event1 = {}
									tournament.registration_event1.url_image = NoImage
									tournament.registration_event1.team_name = tournament.registration_event2 != null && tournament.status == 'finished' ? 'BYE' : 'TBA'
									tournament.registration_event1.user = {}
									tournament.registration_event1.user.name = tournament.registration_event2 != null && tournament.status == 'finished' ? 'BYE' : 'TBA'
								}
								if(tournament.registration_event2 != null)
									tournament.registration_event2.url_image = tournament.registration_event2.url_image != null ? tournament.registration_event2.url_image : NoImage
								else{
									tournament.registration_event2 = {}
									tournament.registration_event2.url_image = NoImage
									tournament.registration_event2.team_name = tournament.registration_event1 != null && tournament.status == 'finished' ? 'BYE' : 'TBA'
									tournament.registration_event2.user = {}
									tournament.registration_event2.user.name = tournament.registration_event1 != null && tournament.status == 'finished' ? 'BYE' : 'TBA'
								}

								// match.date = momentTZ.tz(match.date, this.base.locale_timezone);
								// match.group = null
								// match.member1 = {}
								// match.member2 = {}
								// match.tournament = tournament
								// if(tournament.registration_event1 != null)
								// 	match.member1.registration_event = tournament.registration_event1
								// if(tournament.registration_event2 != null)
								// 	match.member2.registration_event = tournament.registration_event2
							}
						}
					}
					this.arr_group = response.data;
				} else this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
		async onEditSaveClicked(group_data) {
			var temp = group_data
			for(let data of temp.arr){
				data.match = null
			}

			$('#please_wait_modal').modal('show')
			var response = await this.base.request(this.base.url_api + "/tournament/edit/array", 'post', {
				group_data: temp,
			});

			setTimeout(() => {
				$('#please_wait_modal').modal('hide')
			}, 500)

			if (response != null) {
				if (response.status == "success") {
					this.$emit('onSaveClicked')
				}
				else
					this.base.show_error(response.message);
			}
			else
				console.log(this.$t("server_error"));
		},
		onBackPressed(){
			base.back_page()
		},
	}
};
</script>

<template>
	<div class="modal fade" id="editTournament" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">

				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Edit Tournament</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div class="modal-body" v-if="arr_group.length > 0">
					<CustomEditTournament :group="arr_group[0].arr[0]"
						:event_category_sport_category="arr_group[0].event_category_sport_category"
						:type="arr_group[0].type"
						@onSaveClicked="onEditSaveClicked"/>
				</div>

			</div>
		</div>
	</div>
</template>

<style lang="scss">

.match-grid {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	grid-template-rows: auto 1fr;
	gap: 1rem;
}
#about-us-content img {
	width: 100%;
	border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
	min-height: 100px;
	text-align: center;
}

.trust-list>p:nth-child(2) {
	text-align: justify;
	text-align-last: center;
}

.table-member {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
	font-size: 0.75rem;
}

.table-member>tr {
	display: table;
	width: 100%;
}

.btn .caret {
	position: absolute;
	top: calc(50% - 1px);
}
.match-grid-1 { grid-area: 1 / 1 / 2 / 2; }
.match-grid-2 { grid-area: 1 / 2 / 2 / 3; }
.match-grid-3 { grid-area: 1 / 3 / 2 / 4; }
.match-grid-4 { grid-area: 2 / 1 / 3 / 2; z-index: 2; margin: 1rem 0; }
.match-grid-5 { grid-area: 2 / 2 / 3 / 3; z-index: 2; margin: 1rem 0; }
.match-grid-6 { grid-area: 2 / 3 / 3 / 4; z-index: 2; margin: 1rem 0; }
.match-grid-7 {
	grid-area: 2 / 1 / 3 / 4; z-index: 1; position: relative;
	&:after {
		content: "";
		/* width: 100vw; */
		height: 100%;
		background-color: var(--primary);
		position: absolute;
		/* margin: 0 -100vw; */
		left: -50vw;
		// right: 50vw;
		padding: 0 100vw;
		// margin-left: -50vw;
	}
}
// .match-grid-7{
//   grid-area: 2 / 1 / 3 / 4;

// }

// .form-control{
//   background-position: right .75rem center;
// }
</style>
<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail :title="$t('certificate_diploma')" />
    </div>
    <div class="container">
      <div class="my-3 my-md-5">
        <div class="searchBoxGrid mt-3" style="gap: .5rem; min-width: 0;">
          <input type="text" v-model="certificate_id" :placeholder="$t('certificate_id')" class="form-control">

          <p class="mb-0 text-primary text-center">or</p>

          <input type="text" v-model="name" :placeholder="$t('name')" class="form-control">

          <select v-model="event_id" class="form-control">
            <option value="">{{ $t('choose_event') }}</option>
            <option v-for="(event, index) in arr_event" :key="index" :value="event.id">{{ event.name }}</option>
          </select>


          <select v-model="category_sport_id" class="form-control" :disabled="event_id == ''">
            <option value="">{{ $t('choose_category_sport') }}</option>
            <option v-for="(category_sport, index) in arr_category_sport" :key="index" :value="category_sport.id">{{ category_sport.name }}</option>
          </select>

					<select v-model="category_sport_category_id" class="form-control" :disabled="category_sport_id == ''">
						<option value="">{{ $t('choose_event_category_sport_category') }}</option>
						<option v-for="(category_sport_category, index) in arr_event_category_sport_category" :key="index" :value="category_sport_category.id">{{ category_sport_category.name }}</option>
					</select>

					<button class="btn btn-primary text-warning" @click="onValidate">{{ $t('validate') }}</button>

        </div>
      </div>

      <div class="">
        <div class="row">
          <div class="col-12 col-md-6">
            <div v-for="(detail, index) in arr_detail1" :key="index">
              <p class="m-0 certificate-detail mt-3">{{ detail.name }}</p>
              <p class="m-0 text-primary certificate-desc">{{ certificate_data[detail.id] }}</p>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div v-for="(detail, index) in arr_detail2" :key="index">
              <p class="m-0 certificate-detail mt-3">{{ detail.name }}</p>
              <p class="m-0 text-primary certificate-desc">{{ certificate_data[detail.id] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="my-5 text-center d-flex justify-content-center">
      <a class="btn btn-primary text-warning" target="_blank" :href="url">{{ $t('download_certificate') }}</a>
			<button class="btn btn-primary rounded-lg text-warning ml-3" @click="onDownloadParticipant">{{ $t('download_participant') }}</button>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

import NavbarDetail from '@/layout/navbar_detail.vue'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
  },
  data() {
    return {
      base: null,
      scrollY: 0,
      arr_factor: [false, false, false, false,],
      flag: {
        aboutUsTitle1Flag: false,
        aboutUsImage1Flag: false,
        aboutUsContent1Flag: false,
        aboutUsTitle2Flag: false,
        aboutUsImage2Flag: false,
        aboutUsContent2Flag: false,
        aboutUsTitle3Flag: false,
        aboutUsImage3Flag: false,
        aboutUsContent3Flag: false,
      },
      certificate_id: '',
      name: '',
      arr_category_sport: [],
			arr_event_category_sport_category: [],
      arr_event: [],
      category_sport_id: '',
			category_sport_category_id: '',
      event_id: '',
      url: '',
      certificate_data: {},
      arr_detail: [
        {
          id: "certificate_id",
          name: "Certificate ID",
        },
        {
          id: "name",
          name: "Name",
        },
        {
          id: "user_name",
          name: "School",
        },
        {
          id: "event_category_sport_category_name",
          name: "Category",
        },
        {
          id: "player_position_name",
          name: "Position",
        },
        {
          id: "team_name",
          name: "Team",
        },
        {
          id: "award",
          name: "Award",
        },
      ],
    }
  },
  computed: {
    arr_detail1() {
      var arr = []
      for (let x in this.arr_detail) {
        if (x <= this.arr_detail.length / 2)
          arr.push(this.arr_detail[x])
      }
      return arr
    },
    arr_detail2() {
      var arr = []
      for (let x in this.arr_detail) {
        if (x > this.arr_detail.length / 2)
          arr.push(this.arr_detail[x])
				// if(this.arr_detail[x].id == 'team_name')
				// console.log(this.certificate_data)
      }

      return arr
    },
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
    },
    event_id(val) {
      this.get_category_sport()
    },
		category_sport_id(val){
			this.get_category_sport_category()
		},
  },
  created() {
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1
    this.arr_factor = [false,]

    this.get_data()
    this.get_event()
  },
  methods: {
		async onDownloadParticipant(){
			window.$('#please_wait_modal').modal('show')
			var response = await this.base.request(this.base.url_api + "/event/registration/player/check-certificate", 'post', {
				name: this.certificate_data.name,
				event_category_sport_id: this.certificate_data.registration_event.event_category_sport_category.event_category_sport.id,
			})

			setTimeout(() => {
				window.$('#please_wait_modal').modal('hide')
			}, 500)
			if (response != null) {
				if (response.status === "success") {
					window.open(this.base.host + '/export/participant?id=' + this.certificate_data.id, '_blank')
					// window.open(url, '_blank')
				}
				else
					this.base.show_error(response.message)
			}
			else
				console.log(this.$t('server_error'))
		},
    async onValidate() {
      if (this.certificate_id == '' && this.name == '' && this.category_sport_id == '' && this.event_id == '')
        this.base.show_error('Either Certificate ID or Name, Category Sport, and Event must be filled')
      else if (this.certificate_id == '' && this.name == '')
        this.base.show_error('Name is Empty')
      else if (this.certificate_id == '' && this.category_sport_id == '')
        this.base.show_error('Category Sport is Empty')
      else if (this.certificate_id == '' && this.event_id == '')
        this.base.show_error('Event is Empty')
			else if(this.certificate_id == '' && this.category_sport_category_id == '')
				this.base.show_error('Category is Empty')
      else {
        // window.$('#under_development_modal').modal('show')
        var data_check = {}
        var url = ''
        if (this.certificate_id != '') {
          data_check = {
            certificate_id: this.certificate_id,
          }
          url = this.base.host + '/export/certificate?certificate_id=' + this.certificate_id
        }
        else {
          data_check = {
            name: this.name,
            event_category_sport_category_id: this.category_sport_category_id,
          }
          url = this.base.host + '/export/certificate?name=' + this.name + '&event_category_sport_category_id=' + this.category_sport_category_id
        }

        window.$('#please_wait_modal').modal('show')
        var response = await this.base.request(this.base.url_api + "/event/registration/player/check-certificate", 'post', data_check)

        setTimeout(() => {
          window.$('#please_wait_modal').modal('hide')
        }, 500)
        if (response != null) {
          if (response.status === "success") {
            window.location.href = '/certificate?id=' + response.data.certificate_id
            // window.open(url, '_blank')
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    },
    onImageLoad(index) {
      var team = this.arr_team[index]
      team.is_image_loaded = true
      this.$set(this.arr_team, index, team)
    },
    handleScroll() {
      this.scrollY = window.scrollY
    },
    async get_data() {
      var response = await this.base.request(this.base.url_api + "/event/registration/player/all?certificate_id=" + this.$route.query.id)
      this.$set(this.arr_factor, 0, true)

      if (response != null) {
        if (response.status === "success") {
          this.certificate_data = response.data
          this.url = this.base.host + '/export/certificate?certificate_id=' + response.data.certificate_id
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
		async get_category_sport_category() {
			var data = {}
			for(let category_sport of this.arr_category_sport){
				if(category_sport.id == this.category_sport_id){
					data = category_sport
					break
				}
			}

			var response = await this.base.request(this.base.url_api + "/event/category-sport/category/all?event_category_sport_id=" + data.event_category_sport_id)
			// this.$emit('onCompleteRequest', 1)

			if (response != null) {
				if (response.status === "success") {
					this.arr_event_category_sport_category = response.data
				}
				else
					this.base.show_error(response.message)
			}
			else
				console.log(this.$t('server_error'))
		},
    async get_category_sport() {
      var response = await this.base.request(this.base.url_api + "/event/category-sport/all?event_id=" + this.event_id)
      // this.$emit('onCompleteRequest', 1)

      if (response != null) {
        if (response.status === "success") {
          var arr = []
          for (let data of response.data) {
            data.category_sport.event_category_sport_id = data.id
            arr.push(data.category_sport)
          }
          this.arr_category_sport = arr
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_event() {
      var response = await this.base.request(this.base.url_api + "/event/all?type=past_ongoing")
      this.$emit('onCompleteRequest', 0)

      if (response != null) {
        if (response.status === "success") {
          this.arr_event = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/app.scss";

@include media-breakpoint-up(md) {
  .searchBoxGrid {
    grid-template-columns: 1fr auto 1fr 1fr 1fr 1fr 1fr;
  }
  .certificate-desc {
    font-size: 2rem;
  }
}

@include media-breakpoint-down(sm) {
  .searchBoxGrid {
    grid-template-columns: auto;
  }
  .certificate-desc {
    font-size: 1.5rem;
  }
}

.certificate-desc{
  font-family: 'montserrat-bold'
}

.searchBoxGrid {
  display: grid;
  gap: 1rem;
  align-items: center;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.certificate-detail {
  font-family: montserrat-bold;
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}
</style>

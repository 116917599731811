<template>
  <div class="item-childrens">
    <div class="item-child" v-for="(match, index) in arr_prev" :key="index">
      <div class="item" v-if="arr[group_index - 1] != null">
        <div class="item-parent">
          <TournamentMatch :tournament="match" @onMatchClicked="(match) => $emit('onMatchClicked', match)" @onMatchEditClicked="(match) => $emit('onMatchEditClicked', match)" :user="user"/>
        </div>
        <TournamentChildren :arr_prev="arr_tournament_prev_round[index]" :arr="arr" :group_index="group_index - 1" @onMatchClicked="(match) => $emit('onMatchClicked', match)" @onMatchEditClicked="(match) => $emit('onMatchEditClicked', match)" :user="user" v-if="arr_tournament_prev_round.length > 0"/>
      </div>

      <div v-else>
        <TournamentMatch :tournament="match" @onMatchClicked="(match) => $emit('onMatchClicked', match)" @onMatchEditClicked="(match) => $emit('onMatchEditClicked', match)" :user="user"/>
      </div>
    </div>
  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";
import TournamentChildren from "@/pages/competition/component/tournament_children.vue";
import TournamentMatch from "@/pages/competition/component/tournament_match.vue";

export default {
  name: 'TournamentChildren',
  props: ["arr_prev", 'arr', 'group_index', 'user',],
  components: {
    TournamentChildren: TournamentChildren,
    TournamentMatch: TournamentMatch,
  },
  data() {
    return {
      base: null,
      arr_tournament_prev_round: [],
    };
  },
  watch: {
    arr(val){

    },
  },
  async created() {
    this.base = new Base();

    var arr_tournament_prev_round = []
		var arr_match = []
		
		for(let match of this.arr[this.group_index].arr){
			for(let match1 of this.arr_prev){
				if(match1.id == match.id){
					arr_match.push(match)
					break
				}
			}
		}
		
    for(let match of arr_match){
      if(this.arr[this.group_index - 1] != null){
        var arr_match_tournament = []
        for(let match_prev_round of this.arr[this.group_index - 1].arr){
          if(match.from_tournament1.id == match_prev_round.id)
            arr_match_tournament.push(match_prev_round)
          else if(match.from_tournament2.id == match_prev_round.id)
            arr_match_tournament.push(match_prev_round)
        }
        arr_tournament_prev_round.push(arr_match_tournament)
      }
    }
    this.arr_tournament_prev_round = arr_tournament_prev_round
  },
  methods: {},
};
</script>

<style lang="scss">
.matchEventGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
</style>

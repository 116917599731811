<template>
  <div class="h-100 w-100">
    <PaymentBase/>

    <div class="custom-navbar-padding-left custom-navbar-padding-right w-100 d-flex align-items-center justify-content-center" style="overflow-y: scroll;">
      <RegistrationTeam :is_many_team="false"/>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

import PaymentBase from '@/layout/payment_base.vue'
import RegistrationTeam from '@/pages/payment/registration_team.vue'

export default {
  components: {
    'PaymentBase': PaymentBase,
    'RegistrationTeam': RegistrationTeam,
  },
  data(){
    return{
      base: null,
    }
  },
  created(){
    this.base = new Base()
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>

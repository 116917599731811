<template>
  <div>
    <div v-if="arr_match_event.length > 0" class="d-flex flex-column my-4" style="gap: 1.5rem">
      <div v-for="(event, index) in arr_match_event" :key="index" >
        <div v-if="event.type == 'match_finished'" class="mb-3">
          <p class="m-0 text-center">Match Ended</p>
        </div>

        <div class="matchEventGrid" v-else>
          <div
            class="d-flex flex-row-reverse"
            style="gap: 0.5rem"
            v-if="match.registration_event1.id == event.registration_event.id"
          >
            <div class="d-flex align-items-center justify-content-center">
              <button
                class="btn btn-sm btn-danger"
                v-show="user.type.name == 'coordinator' && match.status != 'finished'"
                @click="$emit('onRemoveEventClicked', index)"
              >
                X
              </button>
            </div>
            
            <div
              class="d-flex flex-column flex-md-row flex-fill align-items-start align-items-md-center cursor-pointer"
              style="gap: 0.5rem"
              @click="$emit('onEventClicked', index)"
            >
              <div
                class="d-flex align-items-center justify-content-center"
                style="width: 4rem; height: 4rem"
              >
                <img
                  :src="event.registration_event_player != null ? event.registration_event_player.url_image : event.registration_event.url_image"
                  class="mw-100 mh-100"
                />
              </div>
              <div
                class="d-flex align-items-baseline flex-fill"
                style="gap: 0.5rem"
              >
                <p class="m-0 text-center" style="min-width: 2rem" v-if="event.registration_event_player != null">
                  {{ event.registration_event_player.back_number }}
                </p>
                <div class="d-flex flex-column align-items-start">
                  <p class="m-0 font-weight-bold text-primary">
                    {{ event.registration_event_player != null ? event.registration_event_player.name : event.registration_event.team_name }}
                  </p>
                  <small class="m-0" v-if="event.registration_event_player != null">
                    {{
                      event.registration_event_player.player_position != null
                        ? event.registration_event_player.player_position.name
                        : "-"
                    }}
                  </small>
                </div>
              </div>
              <div class="d-flex align-items-baseline flex-row flex-md-row-reverse" style="gap: 0.5rem">
                <p class="m-0 text-center" style="min-width: 2rem" v-if="event.timer_moment != null">
                  {{ 
										(event.timer_moment.hours() < 10 ? '0' + event.timer_moment.hours() : event.timer_moment.hours()) + ':' + 
										(event.timer_moment.minutes() < 10 ? '0' + event.timer_moment.minutes() : event.timer_moment.minutes()) + ':' + 
										(event.timer_moment.seconds() < 10 ? '0' + event.timer_moment.seconds() : event.timer_moment.seconds()) 
									}}
                </p>
								<div v-else>
									<p class="m-0 text-center" style="min-width: 2rem" v-if="event.minute_time == 0">
										{{ event.total_score }}
									</p>
									
									<p class="m-0 text-center" style="min-width: 2rem" v-else>
										'{{ event.minute_time }}
									</p>
								</div>
                <small v-if="(event.type === 'score')" class="bg-primary m-0 text-nowrap px-2 py-1 font-weight-bold text-white">{{ $t(event.minute_time != 0 ? event.type : event.match.tournament.event_category_sport.cutoff_scoring_type) }}</small>
                <small v-else-if="(event.type === 'yellow_card')" class="bg-warning m-0 text-nowrap px-2 py-1 font-weight-bold text-white">{{ $t(event.type) }}</small>
                <small v-else-if="(event.type === 'red_card')" class="bg-danger m-0 text-nowrap px-2 py-1 font-weight-bold text-white">{{ $t(event.type) }}</small>
              </div>
            </div>
          </div>
          <div class="" v-else></div>

          <div
            class="d-flex flex-row"
            style="gap: 0.5rem"
            v-if="match.registration_event2.id == event.registration_event.id"
          >
            <div class="d-flex align-items-center justify-content-center">
              <button
                class="btn btn-sm btn-danger"
                v-show="user.type.name == 'coordinator' && match.allow_edit && match.status != 'finished'"
                @click="$emit('onRemoveEventClicked', index)"
              >
                X
              </button>
            </div>

            <div
              class="d-flex flex-column flex-md-row-reverse flex-fill align-items-end align-items-md-center cursor-pointer"
              style="gap: 0.5rem"
              @click="$emit('onEventClicked', index)"
            >
              <div
                class="d-flex align-items-center justify-content-center"
                style="width: 4rem; height: 4rem"
              >
                <img
                  :src="event.registration_event_player != null ? event.registration_event_player.url_image : event.registration_event.url_image"
                  class="mw-100 mh-100"
                />
              </div>
              <div
                class="d-flex flex-row-reverse align-items-baseline flex-fill"
                style="gap: 0.5rem"
              >
                <p class="m-0 text-center" style="min-width: 2rem" v-if="event.registration_event_player != null">
                  {{ event.registration_event_player.back_number }}
                </p>
                <div class="d-flex flex-column align-items-end">
                  <p class="m-0 font-weight-bold text-right text-primary">
                    {{ event.registration_event_player != null ? event.registration_event_player.name : event.registration_event.team_name }}
                  </p>
                  <small class="m-0 text-right" v-if="event.registration_event_player != null">
                    {{
                      event.registration_event_player.player_position != null
                        ? event.registration_event_player.player_position.name
                        : "-"
                    }}
                  </small>
                </div>
              </div>
              <div class="d-flex align-items-baseline flex-row-reverse flex-md-row" style="gap: 0.5rem">
                <p class="m-0 text-center" style="min-width: 2rem" v-if="event.timer_moment != null">
									{{ 
										(event.timer_moment.hours() < 10 ? '0' + event.timer_moment.hours() : event.timer_moment.hours()) + ':' + 
										(event.timer_moment.minutes() < 10 ? '0' + event.timer_moment.minutes() : event.timer_moment.minutes()) + ':' + 
										(event.timer_moment.seconds() < 10 ? '0' + event.timer_moment.seconds() : event.timer_moment.seconds()) 
									}}
								</p>
								<div v-else>
									<p class="m-0 text-center" style="min-width: 2rem" v-if="event.minute_time == 0">
										{{ event.total_score }}
									</p>
									
									<p class="m-0 text-center" style="min-width: 2rem" v-else>
										'{{ event.minute_time }}
									</p>
								</div>
                <small v-if="(event.type === 'score')" class="bg-primary m-0 text-nowrap px-2 py-1 font-weight-bold text-white">{{ $t(event.minute_time != 0 ? event.type : event.match.tournament.event_category_sport.cutoff_scoring_type) }}</small>
                <small v-else-if="(event.type === 'yellow_card')" class="bg-warning m-0 text-nowrap px-2 py-1 font-weight-bold text-white">{{ $t(event.type) }}</small>
                <small v-else-if="(event.type === 'red_card')" class="bg-danger m-0 text-nowrap px-2 py-1 font-weight-bold text-white">{{ $t(event.type) }}</small>
              </div>
            </div>

            <!-- <div
              class="d-flex justify-content-between align-items-center w-100"
              @click="$emit('onEventClicked', index)"
            >
              <div class="d-flex">
                <p class="m-0">'{{ event.minute_time }}</p>
                <p class="m-0 ml-3">{{ $t(event.type) }}</p>
              </div>

              <div class="d-flex align-items-center">
                <div class="text-right">
                  <p class="m-0">
                    {{ event.registration_event_player.name }}
                  </p>
                  <p class="m-0">
                    {{
                      event.registration_event_player.player_position != null
                        ? event.registration_event_player.player_position.name
                        : "-"
                    }}
                  </p>
                </div>
                <p class="m-0 ml-3">
                  {{ event.registration_event_player.back_number }}
                </p>
                <img
                  :src="event.registration_event_player.url_image"
                  style="width: 5rem"
                  class="ml-3"
                />
              </div>
            </div>

            <button
              class="btn btn-danger ml-3"
              v-show="user.type.name == 'coordinator' && match.allow_edit"
              @click="$emit('onRemoveEventClicked', index)"
            >
              Remove
            </button> -->
          </div>
          <div class="" v-else></div>
        </div>

        <!-- <div class="row mb-3 vElse" v-else>
          <div class="col-6">
            <div class="d-flex align-items-center" v-if=" (match.tournament != null && match.tournament.registration_event1.id == event.registration_event.id) || (match.tournament == null && event.group_member_id == match.group_member1_id) " >
              <button class="btn btn-danger mr-3" v-show="user.type.name == 'coordinator'" @click="$emit('onRemoveEventClicked', index)" >
                Remove
              </button>
              <div
                class="d-flex justify-content-between align-items-center w-100"
                @click="$emit('onEventClicked', index)"
              >
                <div class="d-flex align-items-center">
                  <img
                    :src="event.registration_event_player.url_image"
                    style="width: 5rem"
                  />
                  <p class="m-0 ml-3">
                    {{ event.registration_event_player.back_number }}
                  </p>
                  <div class="ml-3">
                    <p class="m-0">
                      {{ event.registration_event_player.name }}
                    </p>
                    <p class="m-0">
                      {{
                        event.registration_event_player.player_position != null
                          ? event.registration_event_player.player_position.name
                          : "-"
                      }}
                    </p>
                  </div>
                </div>

                <div class="d-flex">
                  <p class="m-0">{{ $t(event.type) }}</p>
                  <p class="m-0 ml-3">'{{ event.minute_time }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="d-flex align-items-center" v-if=" (match.tournament != null && match.tournament.registration_event2.id == event.registration_event.id) || (match.tournament == null && event.group_member_id == match.group_member2_id) " >
              <div class="d-flex justify-content-between align-items-center w-100" @click="$emit('onEventClicked', index)" >
                <div class="d-flex">
                  <p class="m-0">'{{ event.minute_time }}</p>
                  <p class="m-0 ml-3">{{ $t(event.type) }}</p>
                </div>

                <div class="d-flex align-items-center">
                  <div class="text-right">
                    <p class="m-0">
                      {{ event.registration_event_player.name }}
                    </p>
                    <p class="m-0">
                      {{
                        event.registration_event_player.player_position != null
                          ? event.registration_event_player.player_position.name
                          : "-"
                      }}
                    </p>
                  </div>
                  <p class="m-0 ml-3">
                    {{ event.registration_event_player.back_number }}
                  </p>
                  <img
                    :src="event.registration_event_player.url_image"
                    style="width: 5rem"
                    class="ml-3"
                  />
                </div>
              </div>

              <button class="btn btn-danger ml-3" v-show="user.type.name == 'coordinator' && match.allow_edit" @click="$emit('onRemoveEventClicked', index)" >
                Remove
              </button>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div v-else>
      <p class="text-center">No Data Found</p>
    </div>
  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";

export default {
  props: ["arr_match_event", "match", "user"],
  components: {},
  data() {
    return {
      base: null,
    };
  },
  async created() {
    this.base = new Base();
  },
  methods: {},
};
</script>

<style lang="scss">
.matchEventGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
</style>

<template>
  <div class="card my-5">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <p class="m-0">{{ $t('team_registration') }}</p>
          <div class="d-flex mt-4">
            <img src="" class="rounded-circle" style="width: 3rem; height: 3rem;" />
            <div>
              <p class="m-0">{{ user_data.name }}</p>
              <p class="m-0">{{ user_data.email }}</p>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-4">
            <p class="m-0">{{ $t('phone') }}</p>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">+62</span>
              </div>
              <input type="text" v-model="phone_number" class="form-control">
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-4">
            <p class="m-0">{{ $t('address') }}</p>
            <textarea v-model="address" class="form-control"></textarea>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-4">
            <p class="m-0">{{ $t('province') }}</p>
            <select v-model="province" class="form-control">
              <option value="">{{ $t('select_province') }}</option>
              <option v-for="(province, index) in arr_province" :key="index" :value="province.id">{{ province.name }}</option>
            </select>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-4">
            <p class="m-0">{{ $t('city') }}</p>
            <select v-model="city" class="form-control">
              <option value="">{{ $t('select_city') }}</option>
              <option v-for="(city, index) in arr_city" :key="index" :value="city.id">{{ city.name }}</option>
            </select>
          </div>

          <div>
            <div class="horizontal-line my-5"></div>
          </div>

          <div class="d-flex justify-content-between align-items-center" v-if="is_many_team">
            <p class="m-0">{{ $t('num_team_registered') }}</p>
            <input type="text" v-model="num_team_registered" class="form-control">
          </div>

          <div class="mt-4" v-if="is_many_team">
            <div v-for="(team, index) in arr_team" :key="index">
              <div class="d-flex align-items-center">
                <div class="card">
                  <div class="card-body">
                    <p class="m-0">{{ index + 1 }}</p>
                  </div>
                </div>
                <p class="m-0 ml-3">{{ $t('team_information') }}</p>
              </div>

              <div class="d-flex justify-content-between align-items-center mt-4">
                <p class="m-0">{{ $t('team_name') }}</p>
                <input type="text" v-model="team.name" class="form-control">
              </div>

              <div class="d-flex justify-content-between align-items-center mt-4">
                <p class="m-0">{{ $t('num_player') }}</p>
                <input type="text" v-model="team.num_player" class="form-control">
              </div>

              <div class="d-flex justify-content-between align-items-center mt-4">
                <p class="m-0">{{ $t('competition_category') }}</p>
                <select v-model="team.category_id" class="form-control">
                  <option value="">{{ $t('select_category') }}</option>
                  <option v-for="(category, index) in arr_category" :key="index" :value="category.id">{{ category.name }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="mt-4" v-if="!is_many_team">
            <div>
              <div class="d-flex justify-content-between align-items-center mt-4">
                <p class="m-0">{{ $t('team_name') }}</p>
                <input type="text" v-model="team_name" class="form-control">
              </div>

              <div class="d-flex justify-content-between align-items-center mt-4">
                <p class="m-0">{{ $t('num_player') }}</p>
                <input type="text" v-model="team_num_player" class="form-control">
              </div>

              <div class="d-flex justify-content-between align-items-center mt-4">
                <p class="m-0">{{ $t('competition_category') }}</p>
                <select v-model="team_category_id" class="form-control">
                  <option value="">{{ $t('select_category') }}</option>
                  <option v-for="(category, index) in arr_category" :key="index" :value="category.id">{{ category.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <DetailPayment :arr_team="arr_team" 
            :is_many_team="is_many_team" 
            :category_team="category_team" 
            :arr_button="arr_button"
            @onButtonClicked="onButtonClicked"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

import DetailPayment from '@/pages/payment/detail_payment.vue'

export default {
  components: {
    'DetailPayment': DetailPayment,
  },
  props: ['is_many_team', ],
  data(){
    return{
      base: null,
      scrollY: 0,
      arr_factor: [false, false, false, false, ],
      flag: {
        aboutUsTitle1Flag: false,
        aboutUsImage1Flag: false,
        aboutUsContent1Flag: false,
        aboutUsTitle2Flag: false,
        aboutUsImage2Flag: false,
        aboutUsContent2Flag: false,
        aboutUsTitle3Flag: false,
        aboutUsImage3Flag: false,
        aboutUsContent3Flag: false,
      },
      user_data: {
        name: '',
        email: '',
      },
      arr_province: [],
      arr_city: [],
      arr_category: [],
      event_category_sport_data: {},
      arr_team: [],
      arr_payment_team: [],
      num_team_registered: 0,
      city: '',
      province: '',
      address: '',
      phone_number: '',
      subtotal: 0,
      admin_fee: 0,
      total: 0,
      team_name: '',
      team_num_player: 0,
      team_category_id: '',
      category_team: {},
      arr_button: [
        {
          id: 'submit',
          name_data: 'pay_now',
        },
      ],
    }
  },
  watch: {
    arr_factor(val){
      this.$emit('onChangeArrFactor', val)
      this.manage_start_animation()
    },
    scrollY(){
      this.manage_start_animation()
    },
    num_team_registered(val){
      this.num_team_registered = this.base.to_currency_format(val)

      var arr = []
      for(let x = 0; x < this.base.str_to_double(val); x++)
        arr.push({
          name: '',
          num_player: '0',
          category_id: '',
          category: {},
        })
      this.arr_team = arr
    },
    team_num_player(val){
      this.team_num_player = this.base.to_currency_format(val)
    },
    phone_number(val){
      this.phone_number = this.base.phone_validation(val)
    },
    province(val){
      if(val != '')
        this.get_city()
    },
    arr_team: {
      handler: function(val) {
        for(let team of val){
          team.num_player = this.base.to_currency_format(team.num_player)
          if(team.category_id != ""){
            for(let category of this.arr_category){
              if(category.id == team.category_id){
                team.category = category
                break
              }
            }
          }
        }
        this.arr_team = val
      },
      deep: true,
    },
    team_category_id(val){
      for(let category of this.arr_category){
        if(category.id == val){
          this.category_team = category
          break
        }
      }
    }
  },
  created(){
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1
    this.arr_factor = [true, true, true, true, ]

    this.get_category()
    this.get_province()
  },
  methods: {
    onImageLoad(index){
      var team = this.arr_team[index]
      team.is_image_loaded = true
      this.$set(this.arr_team, index, team)
    },
    manage_start_animation(){
      var context = this
      this.flag.aboutUsTitle1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsTitle1Flag, this.arr_factor, 0)
      this.flag.aboutUsImage1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsImage1Flag, this.arr_factor, 0)
      this.flag.aboutUsContent1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsContent1Flag, this.arr_factor, 0)

      setTimeout(() => {
        if(context.flag.aboutUsContent1Flag){
          var margin = window.$('#about-us-content').height()
          context.flag.aboutUsTitle2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle2Flag, context.arr_factor, 0, margin - margin)
          context.flag.aboutUsContent2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent2Flag, context.arr_factor, 0, margin - 300)
          
          if(context.flag.aboutUsContent2Flag){
            context.flag.aboutUsImage2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage2Flag, context.arr_factor, 0, margin + window.$('#trust-content').innerHeight() - 300)

            if(context.flag.aboutUsImage2Flag){
              margin += window.$('#trust-container').innerHeight() - 950
              context.flag.aboutUsTitle3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsImage3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsContent3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent3Flag, context.arr_factor, 800, margin)
            }
          }
        }
      }, 200)
      
    },
    handleScroll(){
      this.scrollY = window.scrollY
    },
    async get_category(){
      var response = await this.base.request(this.base.url_api + "/event/category-sport/category/all")
      this.$set(this.arr_factor, 0, true)

      if(response != null){
        if(response.status === "success"){
          this.arr_category = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_province(){
      var response = await this.base.request(this.base.url_api + "/province/all")
      this.$set(this.arr_factor, 0, true)

      if(response != null){
        if(response.status === "success"){
          this.arr_province = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_city(){
      var response = await this.base.request(this.base.url_api + "/city/all?province_id=" + this.province)
      this.$set(this.arr_factor, 0, true)

      if(response != null){
        if(response.status === "success"){
          this.arr_city = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    onButtonClicked(index){
      var button = this.arr_button[index]
      if(button.id == "submit"){
        if(this.phone_number == "")
          this.base.show_error(this.$t('phone_number_empty'))
        else if(this.address == "")
          this.base.show_error(this.$t('address_empty'))
        else if(this.province == "")
          this.base.show_error(this.$t('province_empty'))
        else if(this.city == "")
          this.base.show_error(this.$t('city_empty'))
        else if(this.is_many_team && this.arr_team.length == 0)
          this.base.show_error(this.$t('no_team'))
        else if(!this.is_many_team && this.team_name == "")
          this.base.show_error(this.$t('team_name_empty'))
        else if(!this.is_many_team && this.team_num_player == "")
          this.base.show_error(this.$t('team_num_player_empty'))
        else if(!this.is_many_team && this.team_category_id == "")
          this.base.show_error(this.$t('team_category_empty'))
        else{
          var province = {}
          var city = {}
          var data = {}

          for(let province1 of this.arr_province){
            if(province1.id == this.province){
              province = province1
              break
            }
          }
          for(let city1 of this.arr_city){
            if(city1.id == this.city){
              city = city1
              break
            }
          }

          if(this.is_many_team)
            data = {
              phone_number: "+62" + this.phone_number,
              address: this.address,
              province: province,
              city: city,
              arr_team: this.arr_team,
            }
          else{
            var category1 = {}
            for(let category of this.arr_category){
              if(category.id == this.team_category_id){
                category1 = category
                break
              }
            }
            data = {
              phone_number: "+62" + this.phone_number,
              address: this.address,
              province: province,
              city: city,
              team_name: this.team_name,
              team_num_player: this.base.str_to_double(this.team_num_player),
              category: category1,
            }
          }

          window.localStorage.setItem('registration_data', JSON.stringify(data))
          window.location.href = "/registration/choose-payment"
        }
      }
    },
  }
}
</script>

<style lang="scss">
.custom-title{
  color: $primary;
  font-family: poppins-bold;
}
.sponsor-detail{
  font-family: montserrat-light;
  color: $gray20;
}
.sponsor-detail1{
  font-family: montserrat-light;
  color: $primary;
}
.sponsor-why-title{
  // font-family: montserrat-light;
  color: $primary;
  font-size: 2rem;
}
.sponsor-why-detail{
  // font-family: montserrat-light;
  color: $primary;
}
.sponsor-reason-title{
  font-family: montserrat-bold;
  color: $primary;
}
.sponsor-reason-detail{
  font-family: montserrat-light;
  color: $gray20;
}
.about-us-medal-detail{
  color: $gray20;
}
.bg-gray22{
  background-color: $gray22;
}
.trust-card{
  background-color: $gray9;
  border: none;
}
.title-section{
  font-size: 2rem;
  font-family: poppins-medium;
}
.content-section{
  color: $gray6;
}
.team-role{
  color: $black1;
}
.team-name{
  color: $black1;
  font-family: poppins-bold;
}
.about-us-title1-enter-active, .about-us-title1-leave-active,
.about-us-title2-enter-active, .about-us-title2-leave-active,
.about-us-title3-enter-active, .about-us-title3-leave-active{
  transition: all 2s;
}
.about-us-title1-leave-to, .about-us-title1-enter,
.about-us-title2-leave-to, .about-us-title2-enter,
.about-us-title3-leave-to, .about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}
.about-us-content1-enter-active, .about-us-content1-leave-active,
.about-us-content2-enter-active, .about-us-content2-leave-active,
.about-us-content3-enter-active, .about-us-content3-leave-active{
  transition: all 2s;
}
.about-us-content1-leave-to, .about-us-content1-enter,
.about-us-content2-leave-to, .about-us-content2-enter,
.about-us-content3-leave-to, .about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}
.about-us-image1-enter-active, .about-us-image1-leave-active,
.about-us-image2-enter-active, .about-us-image2-leave-active,
.about-us-image3-enter-active, .about-us-image3-leave-active{
  transition: all 2s;
}
.about-us-image1-leave-to, .about-us-image1-enter,
.about-us-image2-leave-to, .about-us-image2-enter,
.about-us-image3-leave-to, .about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}
#about-us-content img{
  width: 100%;
  border-radius: 1rem;
}
.trust-list > p:nth-child(1){
  min-height: 100px;
  text-align: center;
}
.trust-list > p:nth-child(2){
  text-align: justify;
  text-align-last: center;
}
</style>

<template>
  <div class="p-5 w-100 bg-warning ">
    <div class="container">
      <p class="m-0 text-primary text-center supporting-title">{{ $t("check_certificate_title") }}</p>

      <div class="d-none d-md-block">
        <div class="formCertificateGrid mt-3">
          <input type="text" v-model="certificate_id" :placeholder="$t('certificate_id')" class="form-control">
          <p class="mb-0 text-primary">or</p>
          <input type="text" v-model="name" :placeholder="$t('name')" class="form-control">

          <select v-model="event_id" class="form-control">
            <option value="">{{ $t('choose_event') }}</option>
            <option v-for="(event, index) in arr_event" :key="index" :value="event.id">{{ event.name }}</option>
          </select>

					<select v-model="category_sport_id" class="form-control" :disabled="event_id == ''">
						<option value="">{{ $t('choose_category_sport') }}</option>
						<option v-for="(category_sport, index) in arr_category_sport" :key="index" :value="category_sport.id">{{ category_sport.name }}</option>
					</select>

					<select v-model="category_sport_category_id" class="form-control" :disabled="category_sport_id == ''">
						<option value="">{{ $t('choose_event_category_sport_category') }}</option>
						<option v-for="(category_sport_category, index) in arr_event_category_sport_category" :key="index" :value="category_sport_category.id">{{ category_sport_category.name }}</option>
					</select>

					<button class="btn btn-primary text-warning" @click="onValidate">{{ $t('validate') }}</button>
        </div>

      </div>

      <div class="d-block d-md-none">
        <div class="mt-3">
          <div class="">
            <input type="text" v-model="certificate_id" :placeholder="$t('certificate_id')" class="form-control">
          </div>

          <p class="mb-0 text-primary mx-3 text-center my-3">or</p>

          <div class="">
            <div class="">
              <input type="text" v-model="name" :placeholder="$t('name')" class="form-control flex-grow-1 mr-3">

              <select v-model="event_id" class="form-control mr-3 mt-3">
                <option value="">{{ $t('choose_event') }}</option>
                <option v-for="(event, index) in arr_event" :key="index" :value="event.id">{{ event.name }}</option>
              </select>

              <select v-model="category_sport_id" class="form-control mr-3 mt-3" :disabled="event_id == ''">
                <option value="">{{ $t('choose_category_sport') }}</option>
                <option v-for="(category_sport, index) in arr_category_sport" :key="index" :value="category_sport.id">{{ category_sport.name }}</option>
              </select>

							<select v-model="category_sport_category_id" class="form-control" :disabled="category_sport_id == ''">
								<option value="">{{ $t('choose_event_category_sport_category') }}</option>
								<option v-for="(category_sport_category, index) in arr_event_category_sport_category" :key="index" :value="category_sport_category.id">{{ category_sport_category.name }}</option>
							</select>

              <button class="btn btn-primary rounded-lg text-warning w-100 mt-3" @click="onValidate">{{ $t('validate') }}</button>
            </div>
          </div>
        </div>
      </div>

      <p class="m-0 text-primary text-center check-certificate-detail mt-3">{{ $t("check_certificate_detail") }}</p>


    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
// import moment from 'moment';


// import Football from '@/assets/football.png';
// import Futsal from '@/assets/futsal.png';
// import Basket from '@/assets/basket.png';
// import Softball from '@/assets/softball.png';
// import Badminton from '@/assets/badminton.png';
// import Tennis from '@/assets/tennis.png';
// import Taekwondo from '@/assets/taekwondo.png';

export default {
  components: {
  },
  props: ['articleTitle1Flag', 'articleTitle2Flag',],
  data() {
    return {
      base: null,
      arr_category_sport: [],
			arr_event_category_sport: [],
			arr_event_category_sport_category: [],
      arr_event: [],
      certificate_id: '',
      name: '',
      category_sport_id: '',
			category_sport_category_id: '',
      event_id: '',
    }
  },
  watch: {
    isLoading(val) {
      this.$emit("onLoading", val, 2)
    },
    event_id(val){
      this.get_category_sport()
    },
		category_sport_id(val){
			this.get_category_sport_category()
		},
  },
  created() {
    this.base = new Base()

    // this.get_category_sport()
    this.get_event()
  },
  methods: {
		async onDownloadParticipant(){
			if(this.name == '' && this.category_sport_id == '' && this.event_id == '')
				this.base.show_error('Either Certificate ID or Name, Category Sport, and Event must be filled')
			else if(this.name == '')
				this.base.show_error('Name is Empty')
			else if(this.category_sport_id == '')
				this.base.show_error('Category Sport is Empty')
			else if(this.event_id == '')
				this.base.show_error('Event is Empty')
			else{
				var data = {}
				for(let event_category_sport of this.arr_event_category_sport){
					if(event_category_sport.category_sport.id == this.category_sport_id){
						data = event_category_sport
						break
					}
				}

				window.$('#please_wait_modal').modal('show')
				var response = await this.base.request(this.base.url_api + "/event/registration/player/check-certificate", 'post', {
					name: this.name,
					event_category_sport_id: data.id,
				})

				setTimeout(() => {
					window.$('#please_wait_modal').modal('hide')
				}, 500)
				if (response != null) {
					if (response.status === "success") {
						window.open(this.base.host + '/export/participant?name=' + this.name + '&event_category_sport_id=' + data.id, '_blank')
						// window.open(url, '_blank')
					}
					else
						this.base.show_error(response.message)
				}
				else
					console.log(this.$t('server_error'))

			}
		},
    async onValidate(){
      if(this.certificate_id == '' && this.name == '' && this.category_sport_id == '' && this.event_id == '')
        this.base.show_error('Either Certificate ID or Name, Category Sport, Event, and Category must be filled')
      else if(this.certificate_id == '' && this.name == '')
        this.base.show_error('Name is Empty')
      else if(this.certificate_id == '' && this.category_sport_id == '')
        this.base.show_error('Category Sport is Empty')
      else if(this.certificate_id == '' && this.event_id == '')
        this.base.show_error('Event is Empty')
			else if(this.certificate_id == '' && this.category_sport_category_id == '')
				this.base.show_error('Category is Empty')
      else{
        // window.$('#under_development_modal').modal('show')
        var data_check = {}
        var url = ''
        if(this.certificate_id != ''){
          data_check = {
            certificate_id: this.certificate_id,
          }
          url = this.base.host + '/export/certificate?certificate_id=' + this.certificate_id
        }
        else{
          data_check = {
            name: this.name,
            event_category_sport_category_id: this.category_sport_category_id,
          }
          url = this.base.host + '/export/certificate?name=' + this.name + '&event_category_sport_category_id=' + this.category_sport_category_id
        }

        window.$('#please_wait_modal').modal('show')
        var response = await this.base.request(this.base.url_api + "/event/registration/player/check-certificate", 'post', data_check)

        setTimeout(() => {
          window.$('#please_wait_modal').modal('hide')
        }, 500)
        if (response != null) {
          if (response.status === "success") {
            window.location.href = '/certificate?id=' + response.data.certificate_id
            // window.open(url, '_blank')
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    },
    async get_category_sport_category() {
			var data = {}
			for(let category_sport of this.arr_category_sport){
				if(category_sport.id == this.category_sport_id){
					data = category_sport
					break
				}
			}

			var response = await this.base.request(this.base.url_api + "/event/category-sport/category/all?event_category_sport_id=" + data.event_category_sport_id)
			// this.$emit('onCompleteRequest', 1)

			if (response != null) {
				if (response.status === "success") {
					this.arr_event_category_sport_category = response.data
				}
				else
					this.base.show_error(response.message)
			}
			else
				console.log(this.$t('server_error'))
		},
		async get_category_sport() {
      var response = await this.base.request(this.base.url_api + "/event/category-sport/all?event_id=" + this.event_id)
      // this.$emit('onCompleteRequest', 1)

      if (response != null) {
        if (response.status === "success") {
          var arr = []
          for(let data of response.data){
            data.category_sport.event_category_sport_id = data.id
            arr.push(data.category_sport)
          }
          this.arr_category_sport = arr
					this.arr_event_category_sport = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_event() {
      var response = await this.base.request(this.base.url_api + "/event/all?type=past_ongoing&rel_type=simple")
      this.$emit('onCompleteRequest', 0)

      if (response != null) {
        if (response.status === "success") {
          this.arr_event = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  },
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
  .article-first {
    padding-right: .5rem;
  }

  .article-last {
    padding-right: .5rem;
  }

  .article-second {
    padding-left: .5rem;
  }

  .certificate-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media only screen and (min-width: 720px) {
  .article-first {
    padding-right: .5rem;
  }

  .article-last {
    padding-left: .5rem;
  }

  .article-second {
    padding: 0 .5rem;
  }

  .certificate-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.supporting-title {
  font-family: 'montserrat-bold';
  font-size: 2rem;
}

.check-certificate-detail {
  font-family: 'montserrat-regular';
}

.supporting_hr {
  width: 1px;
  border: 1px solid $yellow1;
}

.home-article-title1-enter-active,
.home-article-title1-leave-active {
  transition: all 2s;
}

.home-article-title1-leave-to,
.home-article-title1-enter {
  transform: translateY(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}

.home-article-title2-enter-active,
.home-article-title2-leave-active {
  transition: all 2s;
}

.home-article-title2-leave-to,
.home-article-title2-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.home-article-content-enter-active,
.home-article-content-leave-active {
  transition: all 2s;
}

.home-article-content-leave-to,
.home-article-content-enter {
  transform: translateX(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
.formCertificateGrid {
  display: grid;
  grid-template-columns: 1fr auto 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  align-items: center;
}
</style>
